/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useMemo, useState } from "react";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  add,
  getISOWeek,
  isSameMonth,
  isWeekend,
  format,
  parse,
} from "date-fns";
import CalendarTile from "./CalendarTile";
import { useGetAllAttendancesQuery, useGetAllAbsencesQuery } from "src/store/api/timebookingApi";
import { AbsenceEntry, EmployeeDetails, LogEntry } from "src/store/api/model/TimeBookingData";
import Holidays from "date-holidays";
import logger from "src/utils/logger";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faCalendarWeek, faRepeat, faSwatchbook, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faSquareMinus } from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";

interface CalendarProps {
  date: Date;
  userEmail: string;
  loadingLogs: boolean;
  setLoadingLogs: any;
  userInfo?: EmployeeDetails;
  absences?: AbsenceEntry[];
}

interface LogEntryMap {
  [key: string]: LogEntry[];
}

const Calendar: React.FC<CalendarProps> = ({ date, userEmail, userInfo }) => {
  const firstDayOfMonth = useMemo(() => startOfMonth(date), [date]);
  const lastDayOfMonth = useMemo(() => endOfMonth(date), [date]);

  const { data, error, refetch, isFetching } = useGetAllAttendancesQuery({
    date: format(date, "yyyy-MM-dd"),
  });

  const {
    data: absencesData,
    error: absencesError,
    refetch: absencesRefetch,
    isFetching: absencesIsFetching,
  } = useGetAllAbsencesQuery({
    start_date: format(firstDayOfMonth, "yyyy-MM-dd"),
    end_date: format(lastDayOfMonth, "yyyy-MM-dd"),
  });

  const [dataVersion, setDataVersion] = useState(0);
  const [holidays, setHolidays] = useState<{ [key: string]: string }>({});
  const [partTimeOffDays, setPartTimeOffDays] = useState<string[]>([]);
  const [hideWeekend, setHideWeekend] = useState<boolean>(() => {
    const savedState: string | null = localStorage.getItem("hideWeekend");
    return savedState ? (JSON.parse(savedState) as boolean) : false;
  });

  useEffect(() => {
    if (!userInfo) {
      logger.log("userInfo not loaded or undefined");
      return;
    }

    const state = userInfo?.office_federal_state_short;
    const hd = new Holidays("DE", state);

    const monthHolidays: { [key: string]: string } = {};
    eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth }).forEach(day => {
      const result = hd.isHoliday(day);
      if (result) {
        monthHolidays[format(day, "yyyy-MM-dd")] = result.map(holiday => holiday.name).join(", ");
      }
    });

    setHolidays(monthHolidays);
  }, [date, firstDayOfMonth, lastDayOfMonth, userInfo]);

  useEffect(() => {
    const workDays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
    const offDays = workDays.filter(day => userInfo?.work_schedule[day] === "00:00");
    setPartTimeOffDays(offDays);
  }, [userInfo?.work_schedule]);

  const refreshData = () => {
    setDataVersion(v => v + 1);
  };

  useEffect(() => {
    void refetch();
  }, [dataVersion, refetch]);

  const [logsByDate, setLogsByDate] = useState<LogEntryMap>({});

  useEffect(() => {
    if (data) {
      const newLogsByDate: LogEntryMap = {};
      data.forEach((item: LogEntry) => {
        const formattedDate = format(parse(item.Date, "MM-dd-yyyy", new Date()), "MM-dd-yyyy");
        newLogsByDate[formattedDate] = newLogsByDate[formattedDate] || [];
        newLogsByDate[formattedDate].push(item);
      });
      setLogsByDate(newLogsByDate);
    }
  }, [data]);

  const weeks = [];
  let currentDay = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });

  while (currentDay <= lastDayOfMonth) {
    weeks.push(eachDayOfInterval({ start: currentDay, end: endOfWeek(currentDay, { weekStartsOn: 1 }) }));
    currentDay = add(currentDay, { weeks: 1 });
  }

  const weekdays = hideWeekend ? ["Mo", "Di", "Mi", "Do", "Fr"] : ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

  const dayClassName = (day: Date) => {
    const dayKey = format(day, "yyyy-MM-dd");
    const weekday = format(day, "eeee").toLowerCase();
    const isHoliday = holidays[dayKey];
    const isPartTimeOffDay = partTimeOffDays.includes(weekday);

    return isSameMonth(day, firstDayOfMonth) && !isWeekend(day) && !isHoliday && !isPartTimeOffDay
      ? "bg-base-100"
      : "bg-base-300";
  };

  useEffect(() => {
    localStorage.setItem("hideWeekend", JSON.stringify(hideWeekend));
  }, [hideWeekend]);

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <>
      <ToastContainer />

      <div className="flex justify-end">
        <div className="form-control">
          <label className="label cursor-pointer">
            <button onClick={() => setHideWeekend((prev: boolean) => !prev)} className="btn btn-sm mb-2">
              <span className="label-text">
                {t("timebooking.general.buttons.other.sevendayweek")}
                {hideWeekend ? (
                  <FontAwesomeIcon className="ml-2 text-gray-400" icon={faCircle} />
                ) : (
                  <FontAwesomeIcon className="ml-2 text-success" icon={faCircleCheck} />
                )}
              </span>
            </button>
          </label>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div
          className={`max-xl:hidden grid ${
            hideWeekend ? "lg:grid-cols-5" : "lg:grid-cols-7"
          } md:grid-cols-2 sm:grid-cols-1 gap-4 w-full text-center bg-gray-200 rounded-lg`}
        >
          {weekdays.map((day, idx) => (
            <div key={idx} className="font-semibold">
              {t(`timebooking.general.weekdays.veryshort.${day}`)}
            </div>
          ))}
        </div>
        {weeks.map((week, index) => (
          <React.Fragment key={index}>
            <div className="w-full text-center py-2">
              <div className="divider">
                <span className="text-sm badge badge-light">
                  {t(`timebooking.general.calendarWeekShort`)} {getISOWeek(week[0])}
                </span>
              </div>
            </div>

            <div className={`grid ${hideWeekend ? "grid-cols-5" : "grid-cols-7"} gap-4 w-full`}>
              {week.map(
                (day, dayIndex) =>
                  (!hideWeekend || (hideWeekend && !isWeekend(day))) && (
                    <CalendarTile
                      key={dayIndex}
                      day={day}
                      partTimeOffDays={partTimeOffDays}
                      holidays={holidays}
                      absences={absencesData}
                      isCurrentMonth={isSameMonth(day, firstDayOfMonth)}
                      dayClassName={dayClassName(day)}
                      loadingLogs={isFetching}
                      logs={logsByDate[format(day, "MM-dd-yyyy")]}
                      refreshData={refreshData}
                    />
                  ),
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default Calendar;
