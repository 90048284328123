import React from "react";
import cx from "clsx";

import { BaseComponentProps } from "../../../BaseComponentProps";

interface LabelProps extends BaseComponentProps {
  label?: string;
  htmlFor?: string;
  required?: boolean;
}

/** Label for Form Fields
 */
export default function FormFieldLabel(props: LabelProps): JSX.Element {
  const { label, htmlFor, required, className } = props;

  const classes = cx(className);

  return (
    <label className={classes} htmlFor={htmlFor}>
      {label} {required ? "" : ""}
    </label>
  );
}
