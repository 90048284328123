/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from "react";
import Calendar from "src/components/timebooking/calendar/Calendar";
import FloatingHeadline from "../components/global/headlines/FloatingHeadline";
import { useMsal } from "@azure/msal-react";
import { useGetEmployeeDetailsQuery } from "src/store/api/timebookingApi";
import { useTranslation } from "react-i18next";
// import ReportBugsAlert from "src/components/timebooking/ReportBugsAlert";
import WorkingTime from "src/components/timebooking/WorkingTime";
import MonthSelector from "src/components/timebooking/MonthSelector";
import HolidaysAbsences from "src/components/timebooking/HolidaysAbsences";
import BetaAccessWrapper from "src/components/auth/beta/BetaAccessWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function TimeTracking(): JSX.Element {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [loadingLogs, setLoadingLogs] = useState(false);

  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const [userEmail, setUserEmail] = useState(accounts[0]?.username || "");
  useEffect(() => {
    if (!userEmail && accounts.length > 0) {
      setUserEmail(accounts[0].username);
    }
  }, [accounts, userEmail]); // Beachte die Abhängigkeit zu userEmail

  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    refetch,
  } = useGetEmployeeDetailsQuery({ userEmail }, { skip: !userEmail });

  useEffect(() => {
    if (userEmail) {
      void refetch();
    }
  }, [userEmail, refetch]);

  const handleSelectedMonthChange = (newMonth: React.SetStateAction<Date>) => {
    setSelectedMonth(newMonth);
  };

  const authorizedBetaUserIds = [
    "e6c81552-d6ff-475a-be0a-2527be8d5051", // clemens
    "8948073b-b4ed-4c40-9026-9a065e809d05", // sabrina
    "872466c6-ae9b-41c2-ae18-cdcbfa39ae3f", // dominik
    "04da7013-8591-41e0-8d9f-cab5a8f923f5", // P2
  ];

  return (
    <>
      <BetaAccessWrapper authorizedBetaUserIds={authorizedBetaUserIds}>
        <div className="main-content ml-2 mt-2 mr-6">
          <FloatingHeadline>{t("timebooking.general.headline")}</FloatingHeadline>
          <div className="mt-6 flex justify-center items-center h-screen">
            <div className="stats shadow">
              <WorkingTime isLoadingUserInfo={isLoadingUserInfo} userInfo={userInfo} />
              <MonthSelector onMonthChange={handleSelectedMonthChange} />
              <HolidaysAbsences isLoadingUserInfo={isLoadingUserInfo} userInfo={userInfo} />
            </div>
          </div>

          <div role="alert" className="alert alert-warning mt-8 text-xl">
            <FontAwesomeIcon className="text-error" icon={faInfoCircle} />
            <span className="font-bold">
              Clemens TimeBooking funktioniert weiterhin, ist aber nur sichtbar für Dominik, Clemens, Sabrina und Phil
              Tolstych.
            </span>
          </div>

          {/* <ReportBugsAlert /> */}
          <div className="mt-12 mb-12">
            <Calendar
              date={selectedMonth}
              userEmail={userEmail}
              userInfo={userInfo}
              loadingLogs={loadingLogs}
              setLoadingLogs={setLoadingLogs}
            />
          </div>
        </div>
      </BetaAccessWrapper>
    </>
  );
}
