/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { SubmitHandler } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faKey, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Modal from "src/components/global/modal/Modal";
import { useCreateRequestTicketMutation } from "../../store/api/deskApi";
import Form from "../global/form/form/Form";
import { CreateRequestTicket } from "../../store/api/model/CreateRequestTicket";
import logger from "src/utils/logger";

import SingleCheckbox from "../global/form/checkbox/SingleCheckbox";
import Input from "../global/form/input/Input";
import { BaseComponentProps } from "../BaseComponentProps";

interface ComponentProps extends BaseComponentProps {
  platform?: string;
  email?: string;
}

type CreateRequestTicketFormFields = CreateRequestTicket;

interface ApiResponse {
  email?: string;
}

export default function CreateRequestTicketAction(props: ComponentProps): JSX.Element {
  const { email, platform } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createExperiment] = useCreateRequestTicketMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onError = () => {
    // handle error
  };

  const onSuccess = () => {
    // handle success
    // setIsModalOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (formData: CreateRequestTicketFormFields, event?: React.BaseSyntheticEvent): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    try {
      setIsLoading(true);
      const response = (await createExperiment({ ...formData }).unwrap()) as ApiResponse;
      console.log(response);
    } catch (error) {
      logger.error("Error at createExperiment:", error);
      onError();
    } finally {
      setIsLoading(false);
      setIsSubmitted(true);
    }
  };

  return (
    <>
      <div
        className="tab btn btn-xs btn-primary hover:shadow-lg text-white mr-3 items-center"
        onClick={() => setIsModalOpen(true)}
      >
        <FontAwesomeIcon icon={faKey} /> &nbsp;{" "}
        {t("deskApi.modules.directRequests.modal.buttons.openRequest", { platform: platform })}
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header className="text-center">
          <span className="badge badge-black badge-outline opacity-40 mb-4 text-dark text-xs">Beta Feature</span>
          {!isSubmitted ? (
            <Form<CreateRequestTicketFormFields>
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              onError={onError}
              submitButtonText={
                isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>{t("deskApi.modules.directRequests.modal.buttons.submit")}</>
                )
              }
            >
              <Input id="email" name="email" value={email} hidden label={" "} />
              <Input
                id="description"
                name="description"
                value={`Hiermit beantrage ich Zugriff auf ${platform} via Login Portal.`}
                hidden
                label={" "}
              />
              <Input id="subject" name="subject" value={`Zugriffsanfrage - ${platform}`} hidden label={" "} />

              <Input id="platform" name="platform" value={platform} hidden label={" "} />

              {platform && platform === "AWS" && (
                <SingleCheckbox
                  id="aws_prod"
                  name="aws_prod"
                  label={t("deskApi.modules.directRequests.modal.option", { option: "thinkport-prod" })}
                  registerOptions={{ required: false }}
                />
              )}
              {platform && platform === "AWS" && (
                <SingleCheckbox
                  id="aws_dev"
                  name="aws_dev"
                  label={t("deskApi.modules.directRequests.modal.option", { option: "thinkport-dev" })}
                  registerOptions={{ required: false }}
                />
              )}

              {platform && platform === "AWS" && <hr className="mt-2 mb-2" />}

              <SingleCheckbox
                id="question"
                name="question"
                errorText="Please confirm at first"
                label={t("deskApi.modules.directRequests.modal.title", { platform: platform })}
                registerOptions={{ required: true }}
              />

              <h6>
                <span className="text-xs text-dark">{t("deskApi.modules.directRequests.modal.infoText")}</span>
              </h6>
            </Form>
          ) : (
            <div className="text-success">
              <FontAwesomeIcon icon={faCheckCircle} /> <br />
              {t("deskApi.modules.directRequests.modal.submitSuccess", { platform: platform })}
            </div>
          )}
        </Modal.Header>
      </Modal>
    </>
  );
}
