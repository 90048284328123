import React, { useState, useEffect, useRef } from "react";
import AgentsList from "./AgentsList";
import HeadButton from "../global/button/HeadButton";
import { useGetAllAgentsQuery } from "../../store/api/agentsApi";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faHeart } from "@fortawesome/free-solid-svg-icons";
// import PollOverview from "../polls/PollOverview";
import "../../App.css";
import { useFavorites } from "../favourites/FavoritesContext";

/**
 * Agents overview component
 */
export default function AgentsOverview(): JSX.Element {
  const { data: agentsData } = useGetAllAgentsQuery();
  const { t } = useTranslation();

  // const getCurrentDayLabel = () => {
  //   const date = new Date();
  //   const weekday = date.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
  //   return t(`capacity.weekdays.long.${weekday}`);
  // };
  // const [selectedDay, setSelectedDay] = useState<string>(getCurrentDayLabel());
  // const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  // const today = new Date().toLocaleDateString("en-US", { weekday: "long" });

  // const dayLabels = days.map(day =>
  //   day === today ? t(`capacity.weekdays.short.today`) : t(`capacity.weekdays.short.${day.toLowerCase()}`),
  // );

  // TESTING
  // const [inputValue, setInputValue] = useState("");
  // const [showList, setShowList] = useState(false);
  // const [filter, setFilter] = useState("");

  // const userList = ["Benutzer1", "jonas", "clemens"];

  // const handleChange = (event: { target: { value: any } }) => {
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   const value = event.target.value;
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  //   setInputValue(value);

  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  //   const atIndex = value.indexOf("@");
  //   if (atIndex !== -1) {
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  //     setFilter(value.substring(atIndex + 1));
  //     setShowList(true);
  //   } else {
  //     setShowList(false);
  //   }
  // };

  // const handleSelectUser = (user: string) => {
  //   setInputValue(`@${user}`);
  //   setShowList(false);
  // };

  // const filteredList = filter ? userList.filter(user => user.toLowerCase().startsWith(filter.toLowerCase())) : [];

  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null); // Typisierung für das ref-Element

  const handleScroll = () => {
    if (ref.current) {
      setIsSticky(ref.current.getBoundingClientRect().top <= 60);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const href = e.currentTarget.getAttribute("href");
      if (href) {
        const targetElement = document.querySelector(href) as HTMLElement;
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 190,
            behavior: "smooth",
          });
        }
      }
    };

    // Event Listener für alle Ankers-Links hinzufügenm
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", handleAnchorClick as unknown as EventListener);
    });

    // Entfernen des Event Listeners, wenn die Komponente nicht mehr im DOM ist
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener("click", handleAnchorClick as unknown as EventListener);
      });
    };
  }, []);

  const { ids } = useFavorites();

  return (
    <>
      {/* <div className="grid grid-cols-4">
        <div></div>
        <div className="col-span-2 max-w-full card-bg-color rounded-lg in-card-text overflow-hidden p-4 md:p-6 new-border-radius new-shadow flex flex-col items-center">
          <span className="badge badge-black badge-outline opacity-40 mb-4 in-card-text text-xs">Beta Feature</span>
          <h3 id="capacity" className="text-1xl mb-2 text-dark font-bold leading-none text-center">
            {t("capacity.headline")}
            <div className="mt-2">
              {days.map((day, index) => (
                <button
                  key={day}
                  className={`tab btn btn-xs hover:bg-primary new-border-radius text-white mr-3 ${
                    selectedDay === day ? "bg-primary" : ""
                  }`}
                  onClick={() => setSelectedDay(day)}
                >
                  {dayLabels[index]}
                </button>
              ))}
            </div>
          </h3>
          <div className="tabs mt-2">
            <PollOverview selectedDay={selectedDay} />
          </div>
        </div>
        <div></div>
      </div> */}

      <br />
      <div
        ref={ref}
        style={{ top: "20px", marginLeft: "40px", marginRight: "40px" }} // oder einen anderen Wert für den Abstand nach oben
        className={`sticky top-20 z-50 py-2 ${
          isSticky ? "sticky-header sticky-header-border sticky-header-shadow " : ""
        }`}
      >
        {/* {isSticky && (
          <div className="tabs justify-center mt-2">
            <PollOverview selectedDay={selectedDay} />
          </div>
        )} */}
        <div className="flex justify-center">
          <div className="tabs mt-4 mb-4">
            <HeadButton
              href={"https://thinkport-digital.atlassian.net/wiki/spaces/COM/blog/"}
              className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}
            >
              {t("companyNews")} <FontAwesomeIcon className="ml-2" icon={faExternalLink} />
            </HeadButton>
            {ids.length > 0 && (
              <>
                <HeadButton href={"#favourites"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  {t("favourites")} <FontAwesomeIcon className="text-error ml-2" icon={faHeart} />
                </HeadButton>
              </>
            )}

            <HeadButton href={"#support"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("support")}
            </HeadButton>
            <HeadButton href={"#recently"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("recently")}
            </HeadButton>
            <HeadButton href={"#addresses"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("addresses")}{" "}
            </HeadButton>
            <HeadButton href={"#collaboration"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("collaboration")}{" "}
            </HeadButton>
            <HeadButton href={"#bookings"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("bookings")}{" "}
            </HeadButton>
            <HeadButton href={"#benefits"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("benefits")}
            </HeadButton>
            <HeadButton href={"#other"} className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
              {t("other")}{" "}
            </HeadButton>
          </div>
        </div>
      </div>

      <div className={`main-content mt-6 ${isSticky ? "top-10" : "top-0"}`}>
        <AgentsList entries={agentsData?.data} />
      </div>
    </>
  );
}
