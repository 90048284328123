import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import prepareHeaders from "./prepareAuthHeaders";
import { UserData } from "./model/UserData";
import { PageableResponse } from "./model/BaseResponse";
import { CreateUserRequest } from "./model/CreateUserRequest";
import logger from "src/utils/logger";

/** Api used to access user data
 */
export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["Users"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://6snatpdtwb.execute-api.eu-central-1.amazonaws.com/prod/Search",
    prepareHeaders,
  }),
  endpoints: builder => ({
    getAllUsers: builder.query<PageableResponse<UserData>, void>({
      query: () => "?userId=",
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [...result.data.map(({ id }) => ({ type: "Users", id } as const)), { type: "Users", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Users", id: "LIST" }],
    }),
    getUserInfo: builder.query<UserData, void>({
      query: () => "?userId=",
    }),
    getUserDetailsById: builder.query<UserData, string>({
      query: id => `/${id}?userId=`,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    createUser: builder.mutation<any, Partial<CreateUserRequest>>({
      query(body) {
        logger.log(body);
        return {
          url: "/",
          method: "POST",
          body,
        };
      },
      // Invalidates all Agent-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
  }),
});
export const { useGetUserInfoQuery, useGetAllUsersQuery, useCreateUserMutation, useGetUserDetailsByIdQuery } = userApi;
