import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SearchParams, SearchResultsResponse } from "./model/SearchResultData";
import prepareAuthHeaders from "./prepareAuthHeaders";

/** Api used to access search
 */
export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: fetchBaseQuery({ baseUrl: window.capta_app_config_search, prepareHeaders: prepareAuthHeaders }),
  endpoints: builder => ({
    search: builder.query<SearchResultsResponse, SearchParams>({
      query: params => {
        return {
          url: "/",
          params: {
            q: params.q,
            filter: params.filter,
            sortBy: params.sortBy,
            offset: params.offset,
            size: params.size,
          },
        };
      },
    }),
  }),
});
export const { useSearchQuery } = searchApi;
