import React from "react";
import { BaseComponentProps } from "../../../BaseComponentProps";
import { FieldError } from "react-hook-form";
import cx from "clsx";

interface FormFieldInfoBoxProps extends BaseComponentProps {
  text?: string | React.ReactNode;
  errorText?: string | React.ReactNode;
  error?: FieldError;
}

/**
 * Infobox for FormFields (also used for errors)
 * @param props
 * @constructor
 */
export default function FormFieldInfoBox(props: FormFieldInfoBoxProps): JSX.Element {
  const { text, errorText, error, className } = props;

  if (text === undefined && error === undefined) {
    return <></>;
  }

  const classes = cx(className);

  return (
    <div className={classes}>
      {text}
      {error && (
        <div className={"text-error"}>
          {errorText}
          {error.message}
        </div>
      )}
    </div>
  );
}
