import React from "react";
import { UserData } from "../../store/api/model/UserData";
import FloatingHeadline from "../global/headlines/FloatingHeadline";
import UsersListItem from "./UsersListItem";
import { useTranslation } from "react-i18next";

export interface UsersListProps {
  entries?: UserData[];
}
export default function UsersList(props: UsersListProps): JSX.Element {
  const { entries } = props;
  const { t } = useTranslation();

  if (entries?.length === 0) {
    return (
      <>
        {t("users.noResults")}
        <div className="mt-12 flex justify-center"></div>
      </>
    );
  }
  const cards =
    entries?.map(entry => <UsersListItem key={entry.userId} entry={entry} />) ||
    Array.from({ length: 5 }, (_, k) => <UsersListItem key={`dummy-${k}`} />);
  return (
    <>
      <FloatingHeadline>{t("header.users")}</FloatingHeadline>
      <div className="mb-5 mt-5"></div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-6">{cards}</div>
    </>
  );
}
