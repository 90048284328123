import React from "react";
import cx from "clsx";

import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";

interface ModalBodyProps extends BaseComponentProps, WithChildrenProps {
  onSubmit?: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  id?: string;
}

/**
 * Reusable component that renders a Modal Body
 * @param props
 */

export default function ModalBody(props: ModalBodyProps): JSX.Element {
  const { children, className, onSubmit, id } = props;

  const classes = cx(className, "flex flex-col gap-2");

  return (
    <div className={classes} onSubmit={onSubmit} id={id}>
      {children}
    </div>
  );
}
