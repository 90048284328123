const awsConfig = {
  Auth: {
    Cognito: {
      region: "eu-central-1",
      userPoolId: "WQVoU4iVm",
      userPoolClientId: "71k0ddnob14gjrkcd9sgortkbj",
    },
  },
};

export default awsConfig;
