import React from "react";
import CreateRequestTicketAction from "../agentsPage/CreateRequestTicketAction";
import { useMsal } from "@azure/msal-react";
import LazyImage from "../global/img/LazyImage";
import Favourite from "../favourites/Favourite";
import { TileData } from "src/store/api/model/TileData";
import { t } from "i18next";

const badgeClassNames = {
  info: "badge-info",
  warning: "badge-warning",
  error: "badge-error",
};

/**
 * Eine wiederverwendbare Kachel-Komponente für verschiedene Links, Logos und optionale Inhalte
 */
const Tile: React.FC<TileData> = ({
  href,
  tooltip,
  logoSrc,
  uuid,
  isNew = false,
  badgeText,
  badgeType,
  additionalContent,
  requestActionName,
}) => {
  const badgeClasses = badgeType ? badgeClassNames[badgeType] : "badge-info";

  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const userEmail = accounts[0] && accounts[0].username.toString();

  return (
    <div data-tip={tooltip}>
      <div className="text-left ml-16">
        {isNew ? (
          <div className="indicator">
            <span className="mt-2 uppercase indicator-item badge badge-secondary">{t("new")}</span>
          </div>
        ) : (
          <p>&nbsp;</p>
        )}
      </div>
      <div className="max-w card-border-lg overflow-hidden card-bg-color p-6 hover:cursor-pointer flex flex-col items-center justify-center relative">
        <a href={href}>
          <LazyImage className="rounded-full" src={logoSrc} />
          <div className="text-center">
            {badgeText ? (
              <span className={`badge ${badgeClasses} text-blue-900 font-bold text-sm`}>{badgeText}</span>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </a>
        <Favourite id={uuid} />
      </div>

      {requestActionName && (
        <div className="flex items-center justify-center mt-2">
          <CreateRequestTicketAction email={userEmail} platform={requestActionName} />
        </div>
      )}
      {additionalContent && <div className="flex items-center justify-center mt-2">{additionalContent}</div>}
    </div>
  );
};

export default Tile;
