import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./App";
import awsConfig from "./aws-exports";
import { Amplify } from "aws-amplify";

Amplify.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
