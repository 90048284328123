import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";

interface FavoritesProviderProps {
  children: ReactNode;
}

const FavoritesContext = createContext<{ ids: string[]; toggleId: (id: string) => void } | undefined>(undefined);

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({ children }) => {
  const [ids, setIds] = useState<string[]>(() => {
    const savedIds = document.cookie
      .split("; ")
      .find(row => row.startsWith("favoriteIds="))
      ?.split("=")[1];
    return savedIds ? decodeURIComponent(savedIds).split(",") : [];
  });

  useEffect(() => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 120 * 24 * 60 * 60 * 1000); // 120 Tage in der Zukunft
    document.cookie = `favoriteIds=${encodeURIComponent(ids.join(","))};expires=${expires.toUTCString()};path=/`;
  }, [ids]);

  const toggleId = (id: string) => {
    setIds(prevIds => {
      const isIdPresent = prevIds.includes(id);
      return isIdPresent ? prevIds.filter(currentId => currentId !== id) : [...prevIds, id];
    });
  };

  return <FavoritesContext.Provider value={{ ids, toggleId }}>{children}</FavoritesContext.Provider>;
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error("useFavorites must be used within a FavoritesProvider");
  }
  return context;
};
