import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

interface CalendarMonthSelecterProps {
  onMonthChange: (newMonth: Date) => void;
}

const CalendarMonthSelecter: React.FC<CalendarMonthSelecterProps> = ({ onMonthChange }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date()); // Standardmäßig der aktuelle Monat

  const handlePreviousMonth = () => {
    const prevMonthDate = new Date(selectedMonth);
    prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
    setSelectedMonth(prevMonthDate);
    onMonthChange(prevMonthDate);
  };

  const handleNextMonth = () => {
    const nextMonthDate = new Date(selectedMonth);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    setSelectedMonth(nextMonthDate);
    onMonthChange(nextMonthDate);
  };

  const monthKey = selectedMonth.toLocaleString("default", { month: "long" }).toLowerCase();

  return (
    <div className="calendar-header">
      <div className="stat-value text-black">
        <span
          className="btn btn-ghost mr-4 place-self-end text-2xl hover:cursor-pointer hover:text-error"
          onClick={handlePreviousMonth}
        >
          <FontAwesomeIcon className="text-xl text-gray-600 cursor-pointer" icon={faArrowLeft} />
        </span>
        <span className="stat-value"> {t(`timebooking.general.months.${monthKey}`)}</span>
        <span
          className="btn btn-ghost ml-4 place-self-end text-2xl hover:cursor-pointer hover:text-error"
          onClick={handleNextMonth}
        >
          <FontAwesomeIcon className="text-xl text-gray-600 cursor-pointer" icon={faArrowRight} />
        </span>
      </div>
      <div className="stat-desc text-center text-primary text-lg">{selectedMonth.getFullYear()}</div>
    </div>
  );
};

export default CalendarMonthSelecter;
