/* eslint-disable no-console */
import React from "react";
import { BaseFormFieldProps } from "../form/BaseFormFieldProps";
import FormField from "../form/FormField";

export interface SelectDropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement>, BaseFormFieldProps {
  options: {
    value: string | number;
    label: string;
    selected?: boolean;
  }[];
}

/**
 * Form Component that renders a dropdown (single choice) and registers the selected option
 */
export default function SelectDropdown(props: SelectDropdownProps): JSX.Element {
  const { options, placeholder, label, infoText, errorText, error, control, registerOptions, name, id, ...inputProps } =
    props;

  const selectOptions = [];

  // Standardmäßig sollte der Startwert der Platzhalter sein
  const defaultValue = "PLACEHOLDER";

  if (placeholder) {
    selectOptions.push(
      <option key="" value="" hidden>
        {placeholder}
      </option>,
    );
  }

  // Hinzufügen der dynamischen Optionen
  selectOptions.push(
    options.map((item, index) => (
      <option key={index} value={item.value}>
        {item.label}
      </option>
    )),
  );

  const selectStyles = {
    width: "100%", // This ensures the select takes the full width of its parent
  };

  return (
    <FormField>
      <FormField.Label label={label} htmlFor={id} required={registerOptions?.required === true} />
      <select
        style={selectStyles}
        {...(control !== undefined && name && control.register(name, registerOptions))}
        name={name}
        id={id}
        defaultValue={defaultValue} // Verwenden des standardmäßigen Platzhalterwerts als defaultValue
        {...inputProps}
      >
        {selectOptions}
      </select>
      <FormField.InfoBox text={infoText} errorText={errorText} error={error} />
    </FormField>
  );
}
