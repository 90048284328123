import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faExternalLinkAlt,
  faLightbulb,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "src/components/global/modal/Modal";
import { useCreateUserMutation } from "../../store/api/userApi";
import Form from "../global/form/form/Form";
import Input from "../global/form/input/Input";
import { CreateUserRequest } from "../../store/api/model/CreateUserRequest";
import logger from "src/utils/logger";
import "../../App.css";

type CreateUserFormFields = CreateUserRequest;

interface ApiResponse {
  answer?: string;
  links?: string[];
}

export default function CreateUserAction(): JSX.Element {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createExperiment] = useCreateUserMutation();
  const [answer, setAnswer] = useState<string | null | undefined>(null);
  const [links, setLinks] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // Zustand für die "test"-Nachricht
  const answerNotFoundMessages = ["I could not find an answer.", "Ich konnte keine Antwort finden."];
  const isAnswerFound = answer !== null && answer !== undefined && !answerNotFoundMessages.includes(answer);
  const [submittedQuestion, setSubmittedQuestion] = useState("");
  const [isProcessingResponse, setIsProcessingResponse] = useState(false); // Neuer Zustand für die Ladeanimation

  useEffect(() => {
    if (answer) {
      // Warte zwei Sekunden, bevor die Ladeanimation gestartet wird
      const startLoadingTimer = setTimeout(() => {
        setIsProcessingResponse(true); // Starte die Ladeanimation

        // Warte weitere zwei Sekunden, bevor die Ladeanimation beendet und die "test"-Nachricht angezeigt wird
        const showTestMessageTimer = setTimeout(() => {
          setIsProcessingResponse(false); // Beende die Ladeanimation
          setAlertMessage("-");
        }, 2500); // Zeitdauer, wie lange die Ladeanimation nach ihrem Beginn angezeigt werden soll

        return () => clearTimeout(showTestMessageTimer);
      }, 1500); // Verzögerung vor dem Start der Ladeanimation

      return () => clearTimeout(startLoadingTimer);
    }
  }, [answer]);

  const onError = () => {
    // handle error
  };

  const onSuccess = () => {
    // handle success
    // setIsModalOpen(false);
  };

  const onSubmit = async (formData: CreateUserFormFields): Promise<void> => {
    setSubmittedQuestion(String(formData.question)); // Speichert die eingereichte Frage
    setAlertMessage(""); // Setzt die alertMessage zurück, um das zugehörige Div zu "löschen"
    try {
      setAnswer(null);
      setLinks([]);
      setIsLoading(true);
      const response = (await createExperiment({ ...formData }).unwrap()) as ApiResponse;

      if (response && typeof response.answer === "string") {
        setAnswer(response.answer); // Update the answer state
        if (response.links) {
          setLinks(response.links); // Setze die Links aus der Antwort
        }
      } else {
        // Errorhandling
      }
    } catch (error) {
      logger.error("Error at createExperiment:", error);
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-3">
        <div></div>
        <div
          className="max-w-full rounded-lg overflow-hidden p-4 md:p-6 hover:cursor-pointer new-shadow flex flex-col items-center gradient-button"
          onClick={() => setIsModalOpen(true)}
        >
          {" "}
          <span className="badge badge-black badge-outline opacity-40 mb-4 modal-color text-xs">Beta Feature</span>
          <p className="mb-4">{t("askConfluence")}</p>
          <FontAwesomeIcon icon={faLightbulb} className="text-3xl mb-2" />
          <strong>{t("askConfluenceButton")}</strong>
        </div>
        <div></div>
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header className="text-center">
          <span className="badge badge-black badge-outline opacity-40 mb-1 modal-color text-xs">Beta Feature</span>
        </Modal.Header>
        <Modal.Body>
          {submittedQuestion && (
            <div className="flex justify-end mt-2">
              <div
                className={`speech-bubble speech-bubble-right new-shadow text-right ${
                  !isAnswerFound ? "alert-warning" : "alert-info"
                } rounded-lg p-4`}
                role="alert"
              >
                <p className="">{submittedQuestion}</p>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="flex justify-start mt-2">
              <div className={`speech-bubble speech-bubble-left new-shadow text-left`} role="alert">
                <p className="animate-pulse">
                  <img
                    src="https://emoji.slack-edge.com/TBHSQUY3V/thinkport/af6264b1d8f7ee13.png"
                    height={"32px"}
                    width={"32px"}
                  ></img>
                </p>
              </div>
            </div>
          )}
          {answer && (
            <div className="flex justify-start mt-2">
              <div
                className={`speech-bubble speech-bubble-left new-shadow ${
                  !isAnswerFound ? "alert-warning" : "alert-info"
                } rounded rounded-lg p-4`}
                role="alert"
              >
                <p className="">{answer}</p>
                {isAnswerFound &&
                  [...links].reverse().map((link, index, array) => (
                    <a
                      key={index}
                      href={link}
                      className="btn btn-xs hover:bg-primary new-border-radius text-white py-1 px-3 text-xs mr-2 mt-3"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ float: "right" }}
                    >
                      {`${t("confluenceSource")} ${array.length - index}`}{" "}
                      <FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
                    </a>
                  ))}
              </div>
            </div>
          )}

          {isProcessingResponse && (
            <div className="flex justify-start mt-2">
              <div className={`speech-bubble speech-bubble-left new-shadow text-left`} role="alert">
                <p className="animate-pulse">
                  <img
                    src="https://emoji.slack-edge.com/TBHSQUY3V/thinkport/af6264b1d8f7ee13.png"
                    height={"32px"}
                    width={"32px"}
                  ></img>
                </p>
              </div>
            </div>
          )}
          {alertMessage && (
            <>
              <div className="flex justify-start mt-2">
                <div
                  className={`speech-bubble speech-bubble-left new-shadow ${
                    !isAnswerFound ? "alert-warning" : "alert-info"
                  } rounded rounded-lg p-4`}
                  role="alert"
                >
                  <p className="">
                    <FontAwesomeIcon className="text-new-error" icon={faTriangleExclamation} />{" "}
                    {t("askConfluenceAlertMessageContext")}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="mt-12">
            <Form<CreateUserFormFields>
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              onError={onError}
              submitButtonText={
                isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                    {t("askConfluenceSubmit")} &nbsp;
                    <FontAwesomeIcon icon={faArrowUp} />
                  </>
                )
              }
            >
              <Input
                id="question"
                name="question"
                placeholder={t("askConfluenceInputLabel")}
                className={
                  "w-full p-2 rounded-lg text-input gradient-small-white new-inner-shadow new-border-radius focus:no-border-color focus:outline-none focus:ring "
                }
                registerOptions={{ required: true }}
              />
              <h6>
                <span className="text-sm ml-2 modal-color">{t("askConfluenceWarning")}</span>
              </h6>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
