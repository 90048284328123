import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PageableResponse } from "./model/BaseResponse";
import { AgentData } from "./model/AgentData";
import prepareAuthHeaders from "./prepareAuthHeaders";

/** Api used to access datasources
 */
export const datasourcesApi = createApi({
  reducerPath: "datasourcesApi",
  baseQuery: fetchBaseQuery({ baseUrl: window.capta_app_config_datasources, prepareHeaders: prepareAuthHeaders }),
  endpoints: builder => ({
    getAllDatasources: builder.query<PageableResponse<AgentData>, void>({
      query: () => "",
    }),
    getDatasourceById: builder.query<AgentData, string>({
      query: id => `/${id}`,
    }),
  }),
});
export const { useGetAllDatasourcesQuery, useGetDatasourceByIdQuery } = datasourcesApi;
