import React from "react";
import cx from "clsx";
import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";

interface ButtonProps extends BaseComponentProps, WithChildrenProps {
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  variant?:
    | "default"
    | "primary"
    | "secondary"
    | "form"
    | "border-secondary"
    | "pagination"
    | "close"
    | "landing"
    | "captacolor"
    | "success"
    | "disabledsecondary"
    | "danger"
    | "tag";
  form?: string;
}

/**
 * Reusable component that renders a button
 * @param props
 */

export default function Button(props: ButtonProps): JSX.Element {
  const { className = "btn", children, type, disabled, onClick, variant = "default", form } = props;

  let additionalClasses: string;
  switch (variant) {
    case "default":
      additionalClasses = "";
      break;
    case "secondary":
      additionalClasses = "btn-secondary";
      break;
    case "primary":
      additionalClasses = "btn-primary";
      break;
    case "form":
      additionalClasses = "btn-captacolor hover:btn-active";
      break;
    case "danger":
      additionalClasses = "bg-red-600 hover:bg-red-500 text-white px-5 py-1";
      break;
    case "landing":
      additionalClasses = "bg-captacolor hover:bg-captacolorhover text-white px-5 py-1";
      break;
    case "success":
      additionalClasses = "btn-success";
      break;
    case "border-secondary":
      additionalClasses = "bg-white border border-secondary text-secondary px-5 py-1";
      break;
    case "pagination":
      additionalClasses = "w-14 h-14 m-1 rounded-md bg-shadow text-sm border-gray-200";
      break;
    case "close":
      additionalClasses = "btn bg-white btn-sm";
      break;
    case "disabledsecondary":
      additionalClasses = "btn-warning disabled";
      break;
    case "captacolor":
      additionalClasses = "w-10 h-10 rounded-full bg-captacolor text-primary";
      break;
    case "tag":
      additionalClasses =
        "min-h-[21px] h-[21px] mt-1 relative inline-block bg-captacolor hover:bg-captacolorhover rounded-full px-2 py-0.5 text-xs font-semibold text-white mr-2";
      break;
  }

  const classes = cx(className, additionalClasses, "rounded-lg");

  return (
    <button type={type} form={form} className={classes} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
}
