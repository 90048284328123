import React from "react";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

const CalendarTileProjectName: React.FC<{
  projectName: string;
  taskName: string;
  endTime: string;
  startTime: string;
  bookedHours: number;
  bookedMinutes: number;
}> = ({ projectName, taskName, bookedHours, bookedMinutes, startTime, endTime }) => {
  const truncatedName = truncateText(projectName, 15);

  const tooltipData = `${startTime ? startTime : "N/A"} - ${endTime ? endTime : "N/A"} → ${taskName}`;

  const containerClassName =
    projectName === "Break Time [NEW]"
      ? "flex justify-between min-w-full mb-1 pl-1.5 pr-1.5 pt-0.5 pb-0.5 rounded-md bg-base-300 text-sm whitespace-nowrap"
      : "flex justify-between min-w-full mb-1 pl-1.5 pr-1.5 pt-0.5 pb-0.5 rounded-md timetrack-record-bg-gradient text-sm whitespace-nowrap";

  return (
    <div data-tip={tooltipData} className={containerClassName}>
      <span className="text-xs">
        {projectName === "Break Time [NEW]" ? (
          <FontAwesomeIcon icon={faCoffee} className="text-gray-500" />
        ) : (
          <span className="hover:text-danger">{truncatedName}</span>
        )}
        <ReactTooltip place="top" effect="solid" />
      </span>
      <span className="text-xs">
        {`${String(bookedHours).padStart(2, "0")}:${String(bookedMinutes).padStart(2, "0")}`}
      </span>
    </div>
  );
};

export default CalendarTileProjectName;
