import React from "react";
import { UserData } from "../../store/api/model/UserData";
import { Link } from "react-router-dom";
import cx from "clsx";
import "../../App.css";

interface UsersListItemProps {
  entry?: UserData;
}

export default function UsersListItem(props: UsersListItemProps): JSX.Element {
  const { entry } = props;

  const content = (
    <div className="flex">
      <div className="flex-shrink-0 sm:text-xl w-full mb-6 h-44 sm:h-32 sm:w-16 sm:mb-0">
        <div tabIndex={0} className="avatar placeholder">
          <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
            <span key={entry?.name}>{entry?.name?.split("")[0]}M</span>
            <span key={entry?.lastName}>{entry?.lastName?.split("")[0]}M</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-word-break">
          <h2 className="text-2xl sm:text-lg font-semibold">
            {entry?.name} {entry?.lastName}
            Max Mustermensch
          </h2>
          <span className="in-card-text">{entry?.email} mmuster@thinkport.digital</span>
        </div>
      </div>
    </div>
  );

  const wrapperclassNamees = cx(
    "max-w-md p-5 shadow-lg card-border-lg overflow-hidden card-bg-color",
    entry && "hover:shadow-sm",
  );

  return (
    <div className={wrapperclassNamees}>
      {entry && <Link to={`/user/${encodeURIComponent(entry.id)}`}>{content}</Link>}
      {!entry && <>{content}</>}
    </div>
  );
}
