// src/services/timebookingApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../api";
import {
  TimeBookingData,
  ProjectTaskData,
  LogEntry,
  AbsenceEntry,
  ProjectSuggestionData,
} from "./model/TimeBookingData";
import { CreateAttendancesRequest } from "./model/CreateAttendancesRequest";
import logger from "src/utils/logger";

interface WeekdayQuery {
  weekday: string;
}
/** Api used to access timetracking
 */
export const timebookingApi = createApi({
  reducerPath: "timebookingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://i7259dxdgf.execute-api.eu-central-1.amazonaws.com/dev",
    prepareHeaders: async headers => {
      try {
        const token = await getToken();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
          headers.set("Content-Type", "application/json; charset=UTF-8");
          logger.log("Authorization Header:", `Bearer ${token}`);
        } else {
          logger.error("Token not found");
        }
      } catch (error) {
        logger.error("Error fetching token:", error);
      }
      return headers;
    },
  }),
  tagTypes: ["Attendances"],
  endpoints: builder => ({
    getEmployeeDetails: builder.query({
      query: () => `/user`,
    }),
    getAllAttendances: builder.query<LogEntry[], { date: string }>({
      query: ({ date }) => `/attendances?date=${date}`,
    }),
    getAllAbsences: builder.query<AbsenceEntry[], { start_date: string; end_date: string }>({
      query: ({ start_date, end_date }) => `/absences?start_date=${start_date}&end_date=${end_date}`,
    }),
    deleteAttendances: builder.mutation<LogEntry[], { date: string }>({
      query: ({ date }) => ({
        url: `/attendances?date=${date}`,
        method: "DELETE",
      }),
    }),
    getProjects: builder.query<ProjectTaskData[], string>({
      query: () => `/projects`,
    }),
    getProjectSuggestion: builder.query<ProjectSuggestionData, WeekdayQuery>({
      query: ({ weekday }) => `/projects/suggested?weekday=${weekday}`,
    }),
    getAgentById: builder.query<TimeBookingData, string>({
      query: id => `/${id}`,
      providesTags: (result, error, id) => [{ type: "Attendances", id }],
    }),
    createAttendances: builder.mutation<CreateAttendancesRequest, Partial<CreateAttendancesRequest>>({
      query(body) {
        return {
          url: "/attendances",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Attendances", id: "LIST" }],
    }),
    updateAttendances: builder.mutation<CreateAttendancesRequest, Partial<CreateAttendancesRequest>>({
      query(body) {
        return {
          url: "/attendances",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Attendances", id: "LIST" }],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetEmployeeDetailsQuery,
  useGetAllAttendancesQuery,
  useGetAllAbsencesQuery,
  useDeleteAttendancesMutation,
  useGetAgentByIdQuery,
  useCreateAttendancesMutation,
  useUpdateAttendancesMutation,
  useGetProjectSuggestionQuery,
} = timebookingApi;
