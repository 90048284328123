import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faClock, faGraduationCap, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

const AbsenceRecord: React.FC<{
  absenceType?: string; // Typ der Abwesenheit, z.B. Urlaub, Krankheit usw.
  bookedHours?: number;
}> = ({ absenceType = "", bookedHours = 0 }) => {
  const convertDecimalHoursToTime = (decimalHours: number) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return { hours, minutes };
  };

  const { hours, minutes } = convertDecimalHoursToTime(bookedHours);

  let icon;
  let langKey;

  switch (absenceType) {
    case "Bezahlter Urlaub":
      langKey = "vacation";
      icon = <FontAwesomeIcon icon={faUmbrellaBeach} className="mr-2" />;
      break;
    case "Krankheit":
      langKey = "sickness";
      icon = <FontAwesomeIcon icon={faBed} className="mr-2" />;
      break;
    case "Überstundenausgleich":
      langKey = "overtimereduction";
      icon = <FontAwesomeIcon icon={faClock} className="mr-2" />;
      break;
    case "Training & Hacking":
      langKey = "trainingandhacking";
      icon = <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />;
      break;
    default:
      langKey = "default";
      icon = <FontAwesomeIcon icon={faUmbrellaBeach} className="mr-2" />; // Default fall back to some default icon
  }

  return (
    <div
      className="flex justify-between absence-bg-color min-w-full mb-1 pl-1.5 pr-1.5 pt-0.5 pb-0.5 rounded-md text-sm whitespace-nowrap"
      style={{ backgroundColor: "#ffbfaa" }}
    >
      <span className="text-xs">
        {icon}
        <span>{t(`timebooking.general.absences.types.${langKey}`)}</span>
      </span>
      <span className="text-xs">{`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} `}</span>
    </div>
  );
};

export default AbsenceRecord;
