import React, { useEffect, useRef, useState } from "react";
import cx from "clsx";
import "../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export interface LazyImageProps {
  className?: string;
  src: string | undefined;
  height?: number;
  width?: number;
}

export default function LazyImage(props: LazyImageProps) {
  const { className, src, height, width } = props;

  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const imgEl = imgRef.current;
    if (imgEl) {
      const onImageLoaded = () => setIsLoading(false);
      const onImageError = () => {
        setIsLoading(false);
        setHasError(true);
      };

      imgEl.addEventListener("load", onImageLoaded);
      imgEl.addEventListener("error", onImageError);

      return () => {
        imgEl.removeEventListener("load", onImageLoaded);
        imgEl.removeEventListener("error", onImageError);
      };
    }
  }, [src]);

  return (
    <div className={cx(className, "lazy-image-container")} style={{ height, width }}>
      {(isLoading || hasError) && <FontAwesomeIcon icon={faSpinner} spin />}
      <img
        ref={imgRef}
        className={cx({ hidden: isLoading || hasError })}
        src={src}
        alt=""
        style={{ height, width, objectFit: "cover" }}
      />
    </div>
  );
}
