import React from "react";
import { BaseComponentProps, WithChildrenProps } from "../../../BaseComponentProps";
import cx from "clsx";
import FormFieldLabel from "./FormFieldLabel";
import FormFieldInfoBox from "./FormFieldInfoBox";

interface FormFieldProps extends BaseComponentProps, WithChildrenProps {}

/**
 * Wrapper for Form Fields to provide basic styling
 * @constructor
 */
export default function FormField(props: FormFieldProps): JSX.Element {
  const { className, children } = props;

  const classes = cx(className);
  return <div className={classes}>{children}</div>;
}

FormField.Label = FormFieldLabel;
FormField.InfoBox = FormFieldInfoBox;
