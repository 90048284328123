import React from "react";
import cx from "clsx";

import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";

interface ModalFooterProps extends BaseComponentProps, WithChildrenProps {}

/**
 * Reusable component that renders a Modal Footer
 * @param props
 */

export default function ModalFooter(props: ModalFooterProps): JSX.Element {
  const { children, className } = props;

  const classes = cx(className);
  // const classes = cx(className, "flex flex-row justify-between");

  return <div className={classes}>{children}</div>;
}
