import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  jwtToken: string | undefined;
}

const initialState: UserState = {
  jwtToken: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setJwtToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        jwtToken: action.payload,
      };
    },
  },
});

export const { setJwtToken } = userSlice.actions;
export default userSlice.reducer;
