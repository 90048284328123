import React, { useEffect, useState } from "react";
import { format, isSameDay } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faCopy, faEllipsis, faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AbsenceEntry, DeleteLogEntry, LogEntry } from "src/store/api/model/TimeBookingData";
import CreateAttendanceModal from "../attendances/CreateAttendanceModal";
import CalendarTileProjectName from "./CalendarTileProjectName";
import { useDeleteAttendancesMutation } from "src/store/api/timebookingApi";
import { useMsal } from "@azure/msal-react";
import CalendarSkeleton from "./CalendarSkeleton";
import "../../../App.css";
import AbsenceRecord from "./CalendarTileAbsence";
import CopyAttendanceModal from "../attendances/CopyAttendanceModal";
import { toast } from "react-toastify";
import { t } from "i18next";

interface CalendarTileProps {
  day: Date;
  isCurrentMonth: boolean;
  dayClassName: string;
  logs?: LogEntry[];
  loadingLogs: boolean;
  refreshData: any;
  partTimeOffDays: string[];
  absences?: AbsenceEntry[];
  holidays: {
    [key: string]: string;
  };
}

function calculateTotalBookedBreakTime(logs: LogEntry[]) {
  const filteredLogs = logs.filter(log => log.ProjectName == "Break Time [NEW]");
  let totalMinutes = 0;
  filteredLogs.forEach(log => {
    totalMinutes += log.BookedHours * 60 + log.BookedMinutes;
  });
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

function calculateTotalBookedTimeWithoutBreaks(logs: LogEntry[]) {
  const filteredLogs = logs.filter(log => log.ProjectName !== "Break Time [NEW]");
  let totalMinutes = 0;
  filteredLogs.forEach(log => {
    totalMinutes += log.BookedHours * 60 + log.BookedMinutes;
  });
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

const CalendarTile: React.FC<CalendarTileProps> = ({
  day,
  isCurrentMonth,
  dayClassName,
  logs,
  loadingLogs,
  refreshData,
  holidays,
  absences,
  partTimeOffDays,
}) => {
  const weekday = format(day, "eeee").toLowerCase(); // 'monday', 'tuesday', etc.
  const isPartTimeOffDay = partTimeOffDays.includes(weekday); // Prüfe, ob der Tag in den freien Tagen enthalten ist

  const dayKey = format(day, "yyyy-MM-dd"); // Formatierung des Tags für den Schlüsselvergleich
  const holidayName = holidays[dayKey]; // Suche nach einem Feiertag am gegebenen Tag

  const [absenceEntry, setAbsenceEntry] = useState<AbsenceEntry | null>(null);

  let hasLogs = logs && logs.length > 0;
  const hasLogsAndAbsences = (logs && logs.length > 0) || absenceEntry !== null;

  useEffect(() => {
    // Finding the absence for the given day
    const foundAbsence = absences?.find(absence => absence.date === dayKey);
    setAbsenceEntry(foundAbsence || null); // Set to foundAbsence, or null if not found
  }, [absences, day, dayKey]); // Dependency array includes 'day' to re-run this effect when 'day' changes

  const totalBookedTimeWithoutBreaks = logs ? calculateTotalBookedTimeWithoutBreaks(logs) : { hours: 0, minutes: 0 };
  const totalBookedBreakTime = logs ? calculateTotalBookedBreakTime(logs) : { hours: 0, minutes: 0 };

  // Sortiere die Logs nach ihrer Startzeit
  // eslint-disable-next-line no-console
  console.log(logs);
  const sortedLogs = logs
    ? [...logs].sort((a, b) => {
        const startTimeA = new Date(`1970-01-01T${a.StartTime}`).getTime();
        const startTimeB = new Date(`1970-01-01T${b.StartTime}`).getTime();
        return startTimeA - startTimeB;
      })
    : [];

  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialwert auf das heutige Datum gesetzt
  const [isDeleted, setIsDeleted] = useState(false); // Zustand für gelöschte Einträge
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

  const handleTileClick = () => {
    setSelectedDate(day); // Setzt das Datum der geklickten Kachel
    setIsModalOpen(true); // Öffnet das Modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseCopyModal = () => {
    setIsCopyModalOpen(false);
  };

  const handleCopyModal = () => {
    setSelectedDate(day); // Setzt das Datum der geklickten Kachel
    setIsCopyModalOpen(true); // Öffnet das Copy Modal
  };

  useEffect(() => {}, [isModalOpen]);
  useEffect(() => {}, [isCopyModalOpen]);

  const [deleteAttendances, { isLoading }] = useDeleteAttendancesMutation();

  const formatDate = (datum: Date): string => {
    const jahr = datum.getFullYear();
    const monat = (datum.getMonth() + 1).toString().padStart(2, "0");
    const tag = datum.getDate().toString().padStart(2, "0");
    return `${jahr}-${monat}-${tag}`;
  };
  const handleDelete = async ({ date }: DeleteLogEntry) => {
    try {
      const formattedDate = formatDate(date); // Converts Date to string
      await deleteAttendances({ date: formattedDate }).unwrap();
      toast.success(`Deleted attendance(s)`, {
        autoClose: 12000,
      });

      setIsDeleted(true);
      hasLogs = false;
    } catch (error) {
      toast.success(`Error deleting attendance(s)`, {
        autoClose: 20000,
      });
    }
  };
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const userEmail = accounts[0] && accounts[0].username.toString();

  if (loadingLogs) {
    return (
      <div className="relative card-border-sm p-6 flex justify-center items-center">
        <CalendarSkeleton days={1} />
      </div>
    );
  }

  if (loadingLogs) {
    return (
      <div className="relative card-border-sm p-6 flex justify-center items-center">
        <CalendarSkeleton days={1} />
      </div>
    );
  }

  return (
    <>
      <div
        className={`relative card card-border-sm ${dayClassName} p-6 ${
          isCurrentMonth && !holidayName ? "hover:cursor-pointer" : ""
        } flex flex-col justify-center group`}
        style={{ minWidth: "120px", minHeight: "120px" }}
      >
        {isCurrentMonth && <div className="calendar-tile group" onClick={handleTileClick}></div>}

        <a href={`#day-${format(day, "yyyy-MM-dd")}`} className="absolute top-2 left-2 z-20">
          <div className="text-left">
            {isSameDay(day, new Date()) ? (
              <span className="text-primary font-bold ml-2 text-lg">{format(day, "dd")}</span>
            ) : (
              <span className="ml-2 text-lg">{format(day, "dd")}</span>
            )}
          </div>
        </a>

        <CreateAttendanceModal
          onClose={handleCloseModal}
          isOpen={isModalOpen}
          initialDate={selectedDate}
          userEmail={userEmail}
          onCreated={() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            refreshData();
          }}
          logs={logs}
        />
        <div
          onClick={isCurrentMonth ? handleTileClick : undefined}
          className={`min-w-full ${hasLogsAndAbsences ? "mt-6 mb-8" : ""}`}
          style={{ minHeight: "7rem", maxHeight: "7rem", overflow: "auto" }}
        >
          {hasLogsAndAbsences && !isDeleted ? (
            <>
              {sortedLogs.map((log, index) => (
                <div key={index}>
                  <CalendarTileProjectName
                    taskName={log.TaskName}
                    projectName={log.ProjectName}
                    startTime={log.StartTime}
                    endTime={log.EndTime}
                    bookedHours={log.BookedHours}
                    bookedMinutes={log.BookedMinutes}
                  />
                </div>
              ))}
              <div>
                {absenceEntry && (
                  <>
                    <AbsenceRecord absenceType={absenceEntry?.absence_type} bookedHours={absenceEntry?.hours} />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center" style={{ position: "absolute", inset: 0 }}>
                {isPartTimeOffDay ? (
                  <div className="text-success font-semibold">Dein freier Tag</div> //@todo i18n pflegen
                ) : holidayName ? (
                  <div className="text-primary text-center font-semibold">{holidayName}</div>
                ) : (
                  (!hasLogsAndAbsences || isDeleted) &&
                  isCurrentMonth && (
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-primary text-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                    />
                  )
                )}
              </div>
            </>
          )}
        </div>

        {hasLogs && !isDeleted && (
          <div className="absolute left-1 right-1 bottom-1 flex justify-center items-center">
            <>
              <button className="badge badge-lg font-semibold  bg-base-300 mb-1 text-sm">
                <span className={totalBookedTimeWithoutBreaks.hours > 8 ? "text-error mr-1" : "text-success mr-1"}>
                  {`${String(totalBookedTimeWithoutBreaks.hours).padStart(2, "0")}:${String(
                    totalBookedTimeWithoutBreaks.minutes,
                  ).padStart(2, "0")}`}
                </span>
                {(totalBookedBreakTime.hours !== 0 || totalBookedBreakTime.minutes !== 0) && (
                  <div className="text-gray-500">
                    <span className="mr-1">+</span>
                    {`${String(totalBookedBreakTime.hours).padStart(2, "0")}:${String(
                      totalBookedBreakTime.minutes,
                    ).padStart(2, "0")}`}
                    <FontAwesomeIcon icon={faCoffee} className="ml-1" style={{ fontSize: "67%" }} />
                  </div>
                )}
              </button>
            </>
          </div>
        )}

        {isCurrentMonth && hasLogs && !isDeleted && (
          <div
            className={`dropdown dropdown-top dropdown-end ${
              isLoading ? "dropdown-open" : "dropdown-closed"
            } absolute right-2 top-2 z-20`}
          >
            <button tabIndex={0} className="mr-4 mt-1 text-gray-400">
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
            <ul tabIndex={0} className={`dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-30`}>
              <li>
                <button className="flex justify-between items-center w-full" onClick={() => handleCopyModal()}>
                  {t("timebooking.general.copyEntries")} <FontAwesomeIcon icon={faCopy} />
                </button>
              </li>
              <hr className="mt-2 mb-2" />
              <li>
                <button
                  className="text-error flex justify-between items-center w-full"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={() => handleDelete({ userEmail: userEmail, date: day })}
                  disabled={isLoading}
                >
                  {t("timebooking.general.deleteEntries")}
                  {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTrash} />}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>

      <CopyAttendanceModal
        onClose={handleCloseCopyModal}
        isOpen={isCopyModalOpen}
        initialDate={selectedDate}
        userEmail={userEmail}
        logs={sortedLogs}
        onCreated={() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          refreshData();
        }}
      />
    </>
  );
};

export default CalendarTile;
