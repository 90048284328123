import React from "react";
import { BaseFormFieldProps } from "../form/BaseFormFieldProps";
import cx from "clsx";
import FormField from "../form/FormField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, BaseFormFieldProps {}

/**
 * Text input
 */
export default function Input(props: InputProps): JSX.Element {
  const { label, className, infoText, errorText, error, name, control, registerOptions, id, ...inputProps } = props;
  const classes = cx(className);
  return (
    <FormField>
      {name === "start_time" || name === "end_time" ? (
        <label htmlFor={id} className="ml-1 mr-2">
          {label}
          <FontAwesomeIcon icon={name === "start_time" ? faPlay : faStop} className="ml-2" />
        </label>
      ) : (
        <FormField.Label label={label} htmlFor={id} required={registerOptions?.required === true} />
      )}
      <input
        className={classes}
        {...(control !== undefined && name && control.register(name, registerOptions))}
        name={name}
        id={id}
        {...inputProps}
      />
      <FormField.InfoBox text={infoText} errorText={errorText} error={error} />
    </FormField>
  );
}
