import React, { useState, useEffect, useRef } from "react";
// import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import "../../App.css";
import HeadButton from "../global/button/HeadButton";
import BetaAccessWrapper from "../auth/beta/BetaAccessWrapper";

/**
 * Agents overview component
 */
export default function AgentsOverview(): JSX.Element {
  const authorizedBetaUserIds = [
    "e6c81552-d6ff-475a-be0a-2527be8d5051", // clemens
    "8948073b-b4ed-4c40-9026-9a065e809d05", // sabrina
    "ccf08964-82e5-4219-883d-9f22ff8d22bf", // janina
    "872466c6-ae9b-41c2-ae18-cdcbfa39ae3f", // dominik
    "15b42232-5299-4ab4-9dce-9e68396f13d5", // christina
    "f76f36ae-7921-4a2e-8b41-4cc4f267a56d", // Qin
    "624f1018-932f-4a3a-89ae-2ee2db1c3ca2", // annemarie
    "690b0518-94b2-4211-a6b6-4178956b2787", // markus bommer
    "04da7013-8591-41e0-8d9f-cab5a8f923f5", // P2
  ];

  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null); // Typisierung für das ref-Element

  const handleScroll = () => {
    if (ref.current) {
      setIsSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const href = e.currentTarget.getAttribute("href");
      if (href) {
        const targetElement = document.querySelector(href) as HTMLElement;
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 160,
            behavior: "smooth",
          });
        }
      }
    };

    // Event Listener für alle Ankers-Links hinzufügenm
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", handleAnchorClick as unknown as EventListener);
    });

    // Entfernen des Event Listeners, wenn die Komponente nicht mehr im DOM ist
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener("click", handleAnchorClick as unknown as EventListener);
      });
    };
  }, []);

  return (
    <>
      <BetaAccessWrapper authorizedBetaUserIds={authorizedBetaUserIds}>
        <>
          <div
            ref={ref}
            className={`sticky top-0 z-50 py-2 ${isSticky ? "background-color-sticky-header sticky-soft-shadow" : ""}`}
          >
            <div className="flex justify-center">
              {/* <h1>
                <p className="gradient-text font-bold text-6xl">
                  PROPOSALS<span className="text-black ml-1 font-bold text-7xl mr-3">.</span>
                </p>
              </h1> */}
              <div className="tabs mt-4 mb-4">
                <HeadButton href="#budget" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Budget
                </HeadButton>
                <HeadButton href="#hardware" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Hardware
                </HeadButton>
                <HeadButton href="#software" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Software
                </HeadButton>
                <HeadButton href="#trainings" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Trainings
                </HeadButton>
                <HeadButton href="#keys" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Schlüssel
                </HeadButton>
                <HeadButton href="#conferences" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Konferenzen
                </HeadButton>
                <HeadButton href="#other" className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}>
                  Sonstige
                </HeadButton>
                <HeadButton
                  href="https://thinkport-digital.atlassian.net/wiki/spaces/COM/blog/"
                  className={isSticky ? "button-bg-color" : "button-bg-color-sticky"}
                >
                  Dokumentation <FontAwesomeIcon className="ml-2" icon={faExternalLink} />
                </HeadButton>
              </div>
            </div>
          </div>
          <div className="main-content mt-6">
            <div className={"p-10"}>
              <h1 id="budget" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Budget</div>
              </h1>

              <br />
              <div className="grid grid-cols-2 gap-6">
                <div>
                  {/* Zentraler Inhalt über der Progress Bar */}
                  <div className="relative z-10 flex flex-col items-center justify-center h-full">
                    <p className="text-xl mt-2">Hardware</p>
                    <p className="mb-2">Gesamt 250€</p>
                  </div>
                  <div className="relative max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer">
                    {/* Progress Bar mit weichem Farbverlauf */}
                    <div className="absolute inset-0">
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundImage:
                            "linear-gradient(to right, var(--new-error-color) 0%, var(--new-success-color) 80%)",
                        }}
                      >
                        <div className="flex justify-between h-full p-2">
                          <span className="text-white text-sm font-bold">67,86€</span>
                          <span className="text-white text-sm font-bold">182,14€</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {/* Zentraler Inhalt über der Progress Bar */}
                  <div className="relative z-10 flex flex-col items-center justify-center h-full">
                    <p className="text-xl mt-2">Software / Trainings / Konferenzen</p>
                    <p className="mb-2">Gesamt 1000€</p>
                  </div>
                  <div className="relative max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer">
                    {/* Progress Bar mit weichem Farbverlauf */}
                    <div className="absolute inset-0">
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundImage:
                            "linear-gradient(to right, var(--new-error-color) 0%, var(--new-success-color) 30%)",
                        }}
                      >
                        <div className="flex justify-between h-full p-2">
                          <span className="text-white text-sm font-bold">100€</span>
                          <span className="text-white text-sm font-bold">900€</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"p-10"}>
              <h1 id="hardware" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Hardware</div>
              </h1>
              <br />
              <div className="grid grid-cols-4 gap-6">
                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">iPhone 14 Pro</p>
                      <br />
                      <br />
                      <p className="text-sm text-gray-400">Seriennummer: NWD4V3MWWQ</p>
                      <p className="text-sm mt-2 text-gray-400">Ausgabedatum: 01.01.2024</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-100 text-blue-800 px-3 py-1.5 rounded-full">€999</span>
                      <span className="text-sm bg-green-200 text-green-800 px-3 py-1.5 rounded-full">Aktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">AirPods (3. Generation) mit MagSafe-Ladecase </p>
                      <br />
                      <p className="text-sm text-gray-400">Seriennummer: HHDHJ2209Q</p>
                      <p className="text-sm mt-2 text-gray-400">Ausgabedatum: 01.11.2023</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-100 text-blue-800 px-3 py-1.5 rounded-full">€298</span>
                      <span className="text-sm bg-yellow-200 text-red-800 px-3 py-1.5 rounded-full">Inaktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">Samsung Portable SSD 17 - 500GB</p>
                      <br />
                      <br />
                      <p className="text-sm text-gray-400">Seriennummer: S5TNNSORB12337A</p>
                      <p className="text-sm mt-2 text-gray-400">Ausgabedatum: 03.02.2023</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-100 text-blue-800 px-3 py-1.5 rounded-full">€0</span>
                      <span className="text-sm bg-green-200 text-green-800 px-3 py-1.5 rounded-full">Aktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">Apple Magic Trackpad</p>
                      <br />
                      <br />
                      <p className="text-sm text-red-400">
                        <FontAwesomeIcon className="mr-1" icon={faTriangleExclamation} />
                        Seriennummer: -
                      </p>
                      <p className="text-sm mt-2 text-gray-400">Ausgabedatum: 16.10.2022</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-100 text-blue-800 px-3 py-1.5 rounded-full">€150</span>
                      <span className="text-sm bg-green-200 text-green-800 px-3 py-1.5 rounded-full">Aktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">MacBook Pro (14 Zoll, 2021)</p>
                      <br />
                      <br />
                      <p className="text-sm text-gray-400">Seriennummer: L6VJ2CJXW6</p>
                      <p className="text-sm mt-2 text-gray-400">Ausgabedatum: 27.09.2022</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-100 text-blue-800 px-3 py-1.5 rounded-full">€0</span>
                      <span className="text-sm bg-green-200 text-green-800 px-3 py-1.5 rounded-full">Aktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <div className="w-full animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-md w-3/4 mb-4">&nbsp;</div> {/* Titel-Skelett */}
                    <div className="h-4 bg-gray-200 rounded w-1/2 mb-2">&nbsp;</div> {/* Seriennummer-Skelett */}
                    <div className="h-4 bg-gray-200 rounded w-1/4">&nbsp;</div> {/* Ausgabedatum-Skelett */}
                  </div>
                  <div className="flex justify-between items-end w-full pt-6 animate-pulse">
                    <div className="h-8 bg-gray-200 rounded-full w-24">&nbsp;</div> {/* Preis-Skelett */}
                    <div className="h-8 bg-gray-200 rounded-full w-24">&nbsp;</div> {/* Status-Skelett */}
                  </div>
                </div>

                <div className="max-w card-border-sm card-bg-color flex items-center justify-center p-6 rounded-lg shadow-md h-full">
                  <a href="#" className="w-full h-full flex items-center justify-center">
                    {/* Plus-Icon, das über die gesamte Karte geht */}
                    <div className="text-9xl text-gray-400">+</div>
                  </a>
                </div>
              </div>
            </div>

            <div className={"p-10"}>
              <h1 id="software" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Software</div>
              </h1>
              <br />
              <div className="grid grid-cols-4 gap-6"></div>
            </div>

            <div className={"p-10"}>
              <h1 id="trainings" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Trainings</div>
              </h1>
              <br />
              <div className="grid grid-cols-4 gap-6"></div>
            </div>

            <div className={"p-10"}>
              <h1 id="keys" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Schlüssel</div>
              </h1>
              <br />
              <div className="grid grid-cols-4 gap-6"></div>
            </div>

            <div className={"p-10"}>
              <h1 id="conferences" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Konferenzen</div>
              </h1>
              <br />
              <div className="grid grid-cols-4 gap-6"></div>
            </div>

            <div className={"p-10"}>
              <h1 id="other" className="flex justify-center items-center h-screen mt-8 font-bold leading-none">
                <div className="gradient-text text-5xl">Sonstige</div>
              </h1>
              <br />
              <div className="grid grid-cols-4 gap-6">
                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">Bürostuhl (Flexispot)</p>
                      <br />
                      <p className="text-sm text-gray-400">Seriennummer intern: tp-13532</p>
                      <p className="text-sm text-gray-400">Seriennummer: tp-13532</p>
                      <p className="text-sm mt-2 text-gray-400">Kaufdatum: 27.09.2022</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-200 text-blue-800 px-3 py-1.5 rounded-full">Hamburg</span>
                      <span className="text-sm bg-green-200 text-green-800 px-3 py-1.5 rounded-full">Aktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex flex-col items-start justify-between p-6 rounded-lg shadow-md">
                  <a href="#" className="w-full">
                    <div className="w-full">
                      <p className="text-lg font-semibold mb-1">Monitor (iiyama 34 Zoll)</p>
                      <br />
                      <p className="text-sm text-gray-400">Seriennummer intern: tp-13532</p>
                      <p className="text-sm text-gray-400">Seriennummer: 3hfwh2oq1e3rz</p>
                      <p className="text-sm mt-2 text-gray-400">Kaufdatum: 27.09.2022</p>
                    </div>
                    <div className="flex justify-between items-end w-full pt-6">
                      <span className="text-sm bg-gray-100 text-blue-800 px-3 py-1.5 rounded-full">Hamburg</span>
                      <span className="text-sm bg-green-200 text-green-800 px-3 py-1.5 rounded-full">Aktiv</span>
                    </div>
                  </a>
                </div>

                <div className="max-w card-border-sm card-bg-color flex items-center justify-center p-6 rounded-lg shadow-md h-full">
                  <a href="#" className="w-full h-full flex items-center justify-center">
                    {/* Plus-Icon, das über die gesamte Karte geht */}
                    <div className="text-9xl text-gray-400">+</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      </BetaAccessWrapper>
    </>
  );
}
