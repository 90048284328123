import React from "react";
import AgentsOverview from "../components/agentsPage/AgentsOverview";
import { FavoritesProvider } from "src/components/favourites/FavoritesContext";

export default function AgentsPage(): JSX.Element {
  return (
    <FavoritesProvider>
      <AgentsOverview />
    </FavoritesProvider>
  );
}
