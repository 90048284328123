import React from "react";
import { BaseFormFieldProps } from "../form/BaseFormFieldProps";
import FormField from "../form/FormField";

/**
 * Form Component that renders a single checkbox and registers a boolean value (checked or not checked)
 */
interface SingleCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement>, BaseFormFieldProps {}

export default function SingleCheckbox(props: SingleCheckboxProps): JSX.Element {
  const { label, infoText, errorText, name, error, control, registerOptions, id, ...inputProps } = props;

  return (
    <FormField>
      <input
        {...(control !== undefined && name && control.register(name, registerOptions))}
        name={name}
        id={id}
        {...inputProps}
        type="checkbox"
        className="form-check-input position-static"
      />
      <FormField.Label
        className="text-lg form-check-label position-static ml-2"
        label={label}
        htmlFor={id}
        required={registerOptions?.required === true}
      />
      <FormField.InfoBox text={infoText} errorText={errorText} error={error} />
    </FormField>
  );
}
