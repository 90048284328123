import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Tile from "../tiles/Tile";
import { useFavorites } from "./FavoritesContext";
import { TileData } from "../../store/api/model/TileData";

const FavoritenListe = ({ tilesData }: { tilesData: TileData[] }) => {
  const { ids } = useFavorites();
  const [favoritenTiles, setFavoritenTiles] = useState<TileData[]>([]);

  useEffect(() => {
    const filteredTiles = tilesData.filter(tile => ids.includes(tile.uuid));
    setFavoritenTiles(filteredTiles);
  }, [tilesData, ids]);

  return (
    <TransitionGroup className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
      {favoritenTiles.map(tile => (
        <CSSTransition key={tile.uuid} timeout={800} classNames="fade">
          <Tile
            uuid={tile.uuid}
            href={tile.href}
            logoSrc={tile.logoSrc}
            isNew={tile.isNew}
            requestActionName={tile.requestActionName}
            badgeText={tile.badgeText}
            badgeType={tile.badgeType}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default FavoritenListe;
