import React from "react";
import { WithChildrenProps } from "../../BaseComponentProps";

type MainContainerProps = WithChildrenProps;

/**
 * Component that renders the page's layout
 * @param props
 */

export default function ContentContainer(props: MainContainerProps): JSX.Element {
  const { children } = props;

  return <div>{children}</div>;
}
