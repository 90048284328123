import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";
import logger from "src/utils/logger";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import Button from "../../global/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect(loginRequest).catch((e: any) => {
    logger.error(e);
  });
}

function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch((e: any) => {
    logger.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export default function LoginBtn(): JSX.Element {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return (
      <Button variant="default" onClick={() => handleLogin(instance)}>
        <FontAwesomeIcon icon={faMicrosoft as IconProp} />
        &nbsp;{t("authentication.login")}
      </Button>
    );
  } else {
    return (
      <a className="btn btn-white btn-block btn-sm" onClick={() => handleLogout(instance)}>
        {t("authentication.logout")}&nbsp;&nbsp;
        <FontAwesomeIcon icon={faSignOut} />
      </a>
    );
  }
}
