import React from "react";
import UsersList from "./UsersList";
import { useGetAllUsersQuery } from "../../store/api/userApi";
// import CreateUserAction from "./CreateUserAction";
// import { t } from "i18next";
//import DataPageMenu from "../searchPage/DataPageMenu";

/**
 * Agents overview component
 */
export default function UsersOverview(): JSX.Element {
  const { data } = useGetAllUsersQuery();
  return (
    <>
      <div className="main-content ml-2 mr-6">
        {/*{error && (*/}
        {/*  <div className="mt-6">*/}
        {/*    {t("users.noResults")}*/}
        {/*    <div className="mt-12 flex justify-center">/!*<CreateUserAction />*!/</div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{!error && <UsersList entries={data?.data} />}*/}
        <UsersList entries={data?.data} />
      </div>
    </>
  );
}
