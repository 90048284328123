import React from "react";
import { useMsal } from "@azure/msal-react";

// Definiere ein Interface für die Props der Komponente
interface BetaAccessWrapperProps {
  children: React.ReactNode; // Typisierung für children
  authorizedBetaUserIds: string[]; // Typisierung für authorizedBetaUserIds
}

const BetaAccessWrapper: React.FC<BetaAccessWrapperProps> = ({ children, authorizedBetaUserIds }) => {
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const accountInfo = accounts.length > 0 ? accounts[0] : null;

  const hasBetaAccess = accountInfo ? authorizedBetaUserIds.includes(accountInfo.localAccountId) : false;

  // Render children only if the user has beta access
  return <>{hasBetaAccess ? children : null}</>;
};

export default BetaAccessWrapper;
