import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { agentsApi } from "./api/agentsApi";
import { userApi } from "./api/userApi";
import { pollsApi } from "./api/pollsApi";
import { datasourcesApi } from "./api/datasourcesApi";
import { deskApi } from "./api/deskApi";
import { searchApi } from "./api/searchApi";
import UserReducer from "./reducers/UserReducer";
import { timebookingApi } from "./api/timebookingApi";

export const store = configureStore({
  reducer: {
    [agentsApi.reducerPath]: agentsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [deskApi.reducerPath]: deskApi.reducer,
    [pollsApi.reducerPath]: pollsApi.reducer,
    [datasourcesApi.reducerPath]: datasourcesApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [timebookingApi.reducerPath]: timebookingApi.reducer,
    user: UserReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(agentsApi.middleware)
      .concat(userApi.middleware)
      .concat(deskApi.middleware)
      .concat(datasourcesApi.middleware)
      .concat(pollsApi.middleware)
      .concat(searchApi.middleware)
      .concat(timebookingApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
