import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import logger from "../../utils/logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLanguage } from "@fortawesome/free-solid-svg-icons";
import "../../App.css"; // Assuming you have your styles imported

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language); // useState needs to be imported from 'react'

  const switchLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng).catch((error: any) => {
      // Ensure error handling is appropriately typed
      logger.error(error);
    });
    setSelectedLanguage(lng || "");
  };

  return (
    <div className="dropdown dropdown-top" style={{ width: "100%" }}>
      <div tabIndex={0} role="button" className="btn w-full uppercase btn-block btn-sm">
        <FontAwesomeIcon icon={faLanguage} className="mr-1" />
        {selectedLanguage}
      </div>

      <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
        <li>
          <a onClick={() => switchLanguage("de")}>
            {t("languages.german")}
            {selectedLanguage === "de" && <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" />}
          </a>
        </li>
        <li>
          <a onClick={() => switchLanguage("en")}>
            {t("languages.english")}
            {selectedLanguage === "en" && <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" />}
          </a>
        </li>
      </ul>
    </div>
  );
}

export default LanguageSwitcher;
