/* eslint-disable no-console */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import prepareHeaders from "./prepareAuthHeadersDeskApi";
import { PageableResponse } from "./model/BaseResponse";
import { CreateRequestTicket } from "./model/CreateRequestTicket";
import { DeskData } from "./model/DeskData";

/** Api used to access user data
 */
export const deskApi = createApi({
  reducerPath: "deskApi",
  tagTypes: ["Users"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://fjvew4qqab.execute-api.eu-central-1.amazonaws.com/dev/tickets",
    prepareHeaders,
  }),
  endpoints: builder => ({
    getAllUsers: builder.query<PageableResponse<DeskData>, void>({
      query: () => "?userId=",
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [...result.data.map(({ id }) => ({ type: "Users", id } as const)), { type: "Users", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Users", id: "LIST" }],
    }),
    getUserInfo: builder.query<DeskData, void>({
      query: () => "?userId=",
    }),
    getUserDetailsById: builder.query<DeskData, string>({
      query: id => `/${id}?userId=`,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    createRequestTicket: builder.mutation<any, Partial<CreateRequestTicket>>({
      query(body) {
        return {
          url: "/",
          method: "POST",
          body,
        };
      },
      // Invalidates all Agent-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
  }),
});
export const { useGetUserInfoQuery, useGetAllUsersQuery, useCreateRequestTicketMutation, useGetUserDetailsByIdQuery } =
  deskApi;
