import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PageableResponse } from "./model/BaseResponse";
import { AgentData } from "./model/AgentData";
import { CreateAgentRequest } from "./model/CreateAgentRequest";
import prepareAuthHeaders from "./prepareAuthHeaders";

/** Api used to access agents
 */
export const agentsApi = createApi({
  reducerPath: "agentsApi",
  baseQuery: fetchBaseQuery({ baseUrl: window.capta_app_config_agents, prepareHeaders: prepareAuthHeaders }),
  tagTypes: ["Agents"],
  endpoints: builder => ({
    getAllAgents: builder.query<PageableResponse<AgentData>, void>({
      query: () => "",
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [...result.data.map(({ id }) => ({ type: "Agents", id } as const)), { type: "Agents", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Agents", id: "LIST" }],
    }),
    getAgentById: builder.query<AgentData, string>({
      query: id => `/${id}`,
      providesTags: (result, error, id) => [{ type: "Agents", id }],
    }),
    startAgentRunById: builder.query<AgentData, string>({
      query(id) {
        return {
          url: `/${id}/run`,
          method: "POST",
        };
      },
    }),
    createAgent: builder.mutation<CreateAgentRequest, Partial<CreateAgentRequest>>({
      query(body) {
        return {
          url: "/",
          method: "POST",
          body,
        };
      },
      // Invalidates all Agent-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: [{ type: "Agents", id: "LIST" }],
    }),
  }),
});

export const { useGetAllAgentsQuery, useGetAgentByIdQuery, useStartAgentRunByIdQuery, useCreateAgentMutation } =
  agentsApi;
