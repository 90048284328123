import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface HolidaysAbsencesProps {
  isLoadingUserInfo: boolean;
  userInfo:
    | {
        office_federal_state_short?: string;
      }
    | null
    | undefined;
}

const HolidaysAbsences: React.FC<HolidaysAbsencesProps> = ({ isLoadingUserInfo, userInfo }) => {
  const { t } = useTranslation();
  return (
    <div className="stat place-items-center">
      <div className="stat-title">{t("timebooking.holidays.header.title")}</div>
      {isLoadingUserInfo ? (
        <div className="animate-pulse bg-gray-200 rounded-md w-full h-6 my-2">&nbsp;</div>
      ) : (
        <div className="stat-value text-success text-sm">
          <FontAwesomeIcon icon={faCheckCircle} /> {t("timebooking.general.synced")}
        </div>
      )}
      {isLoadingUserInfo ? (
        <div className="animate-pulse bg-gray-200 rounded-md w-full h-6 my-2">&nbsp;</div>
      ) : (
        <div className="stat-desc">
          {t("timebooking.holidays.header.publicHolidaysSet")}:&nbsp;
          <b>
            {userInfo && userInfo.office_federal_state_short
              ? t(`timebooking.general.federal_states_short.${userInfo.office_federal_state_short}`)
              : ""}
          </b>
        </div>
      )}
    </div>
  );
};

export default HolidaysAbsences;
