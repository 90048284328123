import { t } from "i18next";
import React from "react";
import CalendarMonthSelecter from "src/components/timebooking/calendar/CalendarMonthSelecter";

interface MonthSelectorProps {
  onMonthChange: (newMonth: React.SetStateAction<Date>) => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ onMonthChange }) => {
  return (
    <div className="stat place-items-center">
      <div className="stat-title">{t("timebooking.general.dates.month", { count: 1 })}</div>
      <CalendarMonthSelecter onMonthChange={onMonthChange} />
    </div>
  );
};

export default MonthSelector;
