import { useTranslation } from "react-i18next";
import React from "react";
import logo from "../assets/images/logo_dark.png";
import LoginBtn from "src/components/auth/loginButton/LoginBtn";

export default function LandingPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <section className="px-2 pt-20 bg-white md:px-0">
        <div className="container items-center max-w-6xl px-5 mx-auto space-y-6 text-center">
          <span className="flex items-center justify-center">
            <img src={logo} alt={t("logo.alt-text")} className="w-100 h-10" />
          </span>
          <h1 className="text-4xl font-extrabold tracking-tight text-left text-gray-900 sm:text-1xl md:text-6xl md:text-center flex-wrap">
            <span className="block">{t("brandName")}</span>
          </h1>
          <span>
            <br />
            <LoginBtn />
          </span>
          <div className="relative flex flex-col justify-center md:flex-row md:space-x-4">
            <span className="block">{t("siteDescription")}</span>
          </div>
        </div>

        <br />
      </section>
    </>
  );
}
