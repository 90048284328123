import React, { useEffect, useState } from "react";
import "./SpecialModalAd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

interface SpecialModalAdProps {
  delay?: number;
  reappearHours?: number;
  imageSrc: string;
}

const SpecialModalAd: React.FC<SpecialModalAdProps> = ({ delay = 2000, reappearHours = 72, imageSrc }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const lastDisplay = localStorage.getItem("lastModalDisplay");
    const now = new Date();
    const reappearMilliseconds = reappearHours * 60 * 60 * 1000;

    if (!lastDisplay || now.getTime() - new Date(parseInt(lastDisplay)).getTime() > reappearMilliseconds) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        document.body.style.overflow = "hidden";
        localStorage.setItem("lastModalDisplay", now.getTime().toString());
      }, delay);

      return () => {
        clearTimeout(timer);
        document.body.style.overflow = "unset";
      };
    }
  }, [delay, reappearHours, imageSrc]);

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={closeModal}>
        <img src={imageSrc} alt="Modal Content" className="modal-image" />
        <FontAwesomeIcon icon={faTimesCircle} className="close-icon" onClick={closeModal} />
      </div>
    </div>
  );
};

export default SpecialModalAd;
