import React, { useEffect } from "react";
import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ModalBody from "./ModalBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface ModalProps extends BaseComponentProps, WithChildrenProps {
  open: boolean;
  minWidth?: string;
  maxWidth?: string;
  onClose?: () => void;
}

export default function Modal(props: ModalProps): JSX.Element {
  const { onClose, children, open, minWidth = "55%", maxWidth = "55%" } = props;

  // Use useEffect to toggle the overflow style on the body when the modal opens or closes
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]); // Dependency array includes 'open' to react to its changes

  if (!open) {
    return <></>;
  }

  return (
    <div className="flex justify-center items-center overflow-x-hidden cursor-default overflow-y-auto fixed inset-0 z-[100] modal-blur-background">
      <div className="flex flex-col rounded-3xl p-8 modal-bg-color" style={{ maxWidth, minWidth }}>
        {onClose && (
          <span
            className="btn btn-ghost place-self-end text-2xl hover:cursor-pointer hover:text-error"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
        <div className="flex flex-col gap-3">{children}</div>
      </div>
    </div>
  );
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
