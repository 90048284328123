import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faSquareCaretLeft } from "@fortawesome/free-regular-svg-icons";

type ToggleSidebarButtonProps = {
  isCollapsed: boolean;
  toggleAside: () => void;
};

const ToggleSidebarButton: React.FC<ToggleSidebarButtonProps> = ({ isCollapsed, toggleAside }) => {
  return (
    <button
      onClick={toggleAside}
      className={`z-10 btn ${isCollapsed ? "btn-lg text-4xl btn-ghost place-self-end" : "btn-sm hover:text-error"}`}
      style={{
        position: "fixed",
        top: "1rem",
        left: isCollapsed ? "1rem" : "calc(18rem - 1.5rem)",
        transform: isCollapsed ? "none" : "translateX(-50%)",
      }}
    >
      <FontAwesomeIcon icon={isCollapsed ? faBars : faSquareCaretLeft} />
    </button>
  );
};

export default ToggleSidebarButton;
