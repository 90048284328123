import React from "react";
import cx from "clsx";

import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";

interface ModalHeaderProps extends BaseComponentProps, WithChildrenProps {}

/**
 * Reusable component that renders a Modal Header
 * @param props
 */

export default function ModalHeader(props: ModalHeaderProps): JSX.Element {
  const { children, className } = props;

  const classes = cx(className, "text-2xl");

  return <h2 className={classes}>{children}</h2>;
}
