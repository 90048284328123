/* eslint-disable no-console */
/**
 * Central Logger that should be used to print messages instead of console.log.
 *
 */
class Logger {
  get debugModeActive() {
    return process.env.NODE_ENV === "development";
  }

  log(message?: any, ...args: unknown[]) {
    if (!this.debugModeActive) return;
    console.log(message, ...args);
  }

  warn(message?: any, ...args: unknown[]) {
    if (!this.debugModeActive) return;
    console.warn(message, ...args);
  }

  error(message?: any, ...args: unknown[]) {
    if (!this.debugModeActive) return;
    console.error(message, ...args);
  }
}

export default new Logger();
/* eslint-enable no-console */
