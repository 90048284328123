import { faCompass } from "@fortawesome/free-regular-svg-icons";
import {
  faBusinessTime,
  faExternalLink,
  faHandHoldingDollar,
  faLaptop,
  faLocationDot,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/logo_dark.png";
import LoginBtn from "../../auth/loginButton/LoginBtn";
// import DarkMode from "src/components/darkmode/DarkMode";
import "../../../App.css";
import "./Header.css";
import LanguageSwitcher from "../../languageswitch/LanugageSwitch";
import BetaAccessWrapper from "src/components/auth/beta/BetaAccessWrapper";

import ThemeSwitcher from "src/components/darkmode/ThemeSelector";

export default function Header(): JSX.Element {
  const { t } = useTranslation();
  const authorizedBetaUserIds = [
    "e6c81552-d6ff-475a-be0a-2527be8d5051", // clemens
    "8948073b-b4ed-4c40-9026-9a065e809d05", // sabrina
    "ccf08964-82e5-4219-883d-9f22ff8d22bf", // janina
    "872466c6-ae9b-41c2-ae18-cdcbfa39ae3f", // dominik
    "15b42232-5299-4ab4-9dce-9e68396f13d5", // christina
    "f76f36ae-7921-4a2e-8b41-4cc4f267a56d", // Qin
    "624f1018-932f-4a3a-89ae-2ee2db1c3ca2", // annemarie
    "690b0518-94b2-4211-a6b6-4178956b2787", // markus bommer
    "04da7013-8591-41e0-8d9f-cab5a8f923f5", // P2
  ];

  // const authorizedBetaUserIdsTimeBooking = [
  //   "e6c81552-d6ff-475a-be0a-2527be8d5051", // clemens
  //   "8948073b-b4ed-4c40-9026-9a065e809d05", // sabrina
  //   "872466c6-ae9b-41c2-ae18-cdcbfa39ae3f", // dominik
  //   "04da7013-8591-41e0-8d9f-cab5a8f923f5", // P2
  //   "28f9f559-a266-4771-ad8b-bf69142cf724", // Felix B
  // ];

  return (
    <>
      <div className="sidebar z-[2] md:sidebar-small card-bg-color">
        <div className="sidebar-top">
          <Link to="https://thinkport.digital" className="sidebar-logo">
            <img src={logo} alt={t("logo.alt-text")} data-tip={t("openThinkport")} />
          </Link>

          <div className="ml-4 mr-4">
            <span className="sidebar-divider mt-4 mb-6"></span>
            <Link className="sidebar-item sidebar-item-hover-background-color nav-items-text-headline" to={"/"}>
              <FontAwesomeIcon className="sidebar-icon" icon={faCompass} />
              <span className="hidden md:block">{t("header.navigator")}</span>
            </Link>
            <div className="relative flex flex-col items-center">
              <Link
                className="sidebar-item sidebar-item-hover-background-color text-error nav-items-text-headline"
                to={"/time-booking"}
              >
                <span className="sidebar-icon-and-text">
                  <FontAwesomeIcon className="sidebar-icon" icon={faBusinessTime} />
                  <span className="mr-2">{t("timebooking.general.headline")}</span>
                  <FontAwesomeIcon icon={faExternalLink} />
                </span>
              </Link>
              <span className="badge badge-warning" style={{ position: "absolute", top: "1px", zIndex: 10 }}>
                Now via xIT
              </span>
            </div>
            <BetaAccessWrapper authorizedBetaUserIds={authorizedBetaUserIds}>
              <>
                <div className="relative flex flex-col items-center">
                  <Link
                    className="sidebar-item sidebar-item-hover-background-color text-error nav-items-text-headline"
                    to={"/deprecated-time-booking"}
                  >
                    <span className="sidebar-icon-and-text">
                      <FontAwesomeIcon className="sidebar-icon" icon={faBusinessTime} />
                      <span className="mr-2">{t("timebooking.general.headline")}</span>
                    </span>
                  </Link>
                  <span className="badge badge-info" style={{ position: "absolute", top: "1px", zIndex: 10 }}>
                    Restricted Access
                  </span>
                </div>
                <Link
                  className="sidebar-item sidebar-item-hover-background-color nav-items-text-headline"
                  to={"/my-inventory"}
                >
                  <span className="sidebar-icon-and-text">
                    <FontAwesomeIcon className="sidebar-icon" icon={faLaptop} />
                    <span>{t("header.personalinventory")}</span>
                  </span>
                </Link>

                <Link
                  className="sidebar-item sidebar-item-hover-background-color nav-items-text-headline"
                  to={"/general-inventory"}
                >
                  <span className="sidebar-icon-and-text">
                    <FontAwesomeIcon className="sidebar-icon" icon={faLocationDot} />
                    <span>{t("header.generalinventory")}</span>
                  </span>
                </Link>

                <Link
                  className="sidebar-item sidebar-item-hover-background-color nav-items-text-headline"
                  to={"/benefit-management"}
                >
                  <span className="sidebar-icon-and-text">
                    <FontAwesomeIcon className="sidebar-icon" icon={faHandHoldingDollar} />
                    <span>{t("header.benefits")}</span>
                  </span>
                </Link>

                {/* <BetaAccessWrapper authorizedBetaUserIds={authorizedBetaUserIdsTimeBooking}> */}

                {/* </BetaAccessWrapper> */}

                <span className="sidebar-divider mt-4"></span>
                <span className="sidebar-headline-item sidebar-headline">Administration</span>
                <Link
                  className="sidebar-item sidebar-item-hover-background-color nav-items-text-headline"
                  to={"/users"}
                >
                  <span className="sidebar-icon-and-text">
                    <FontAwesomeIcon className="sidebar-icon" icon={faUsers} />
                    <span>{t("header.users")}</span>
                  </span>
                </Link>
              </>
            </BetaAccessWrapper>

            <div className="sidebar-bottom">
              <div className="rounded-2xl p-1 bg-base-300">
                <div className="mt-2 m-2 gap-2">
                  <div className="flex justify-center items-center gap-2">
                    <ThemeSwitcher />
                    <LanguageSwitcher />
                  </div>
                  <div className="flex justify-center items-center mt-2">
                    <a
                      className="btn btn-block btn-sm"
                      href="https://thinkport-digital.atlassian.net/wiki/spaces/COM/pages/3491692562/Changelogs"
                    >
                      Changelogs <FontAwesomeIcon className="ml-2" icon={faExternalLink} />
                    </a>
                  </div>
                </div>

                <div className="m-2 mt-8">
                  <LoginBtn />
                </div>
              </div>

              <div className="mt-4 w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
