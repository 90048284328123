/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { UseFormSetValue } from "react-hook-form";

interface TimeInputProps {
  name: string;
  register: any;
  minTime?: string;
  setValue: UseFormSetValue<any>;
  icon: "play" | "stop";
  value: string;
}

const TimeInput: React.FC<TimeInputProps> = ({ name, register, minTime, setValue, icon, value }) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [error, setError] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // Nur Zahlen und Doppelpunkte zulassen
    if (/^[0-9:]*$/.test(newValue)) {
      setInputValue(newValue);
      setValue(name, newValue, { shouldValidate: true });
      setTouched(true);
    }
  };

  const formatTime = (time: string) => {
    if (time.includes(":")) {
      const [hour, minute] = time.split(":");
      return `${hour.padStart(2, "0")}:${(minute || "00").padStart(2, "0")}`;
    } else if (time.length === 4) {
      return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
    } else if (time.length === 3) {
      return `0${time[0]}:${time.slice(1, 3)}`;
    } else if (time.length === 2) {
      return `${time.padStart(2, "0")}:00`;
    } else if (time.length === 1) {
      return `0${time}:00`;
    } else {
      return time;
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() === "") {
      setError(false);
      return;
    }

    const formattedValue = formatTime(inputValue);

    const timePattern = /^(\d{2}):(\d{2})$/;
    const match = formattedValue.match(timePattern);

    if (match) {
      const [, hour, minute] = match;

      if (parseInt(hour) < 24 && parseInt(minute) < 60) {
        setInputValue(formattedValue);
        setValue(name, formattedValue, { shouldValidate: true });
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <label className="input input-ghost flex items-center gap-2">
        <FontAwesomeIcon icon={icon === "play" ? faPlay : faStop} />
        <input
          {...register(name)}
          type="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          style={{ width: "100%" }}
          min={minTime}
          placeholder="hh:mm"
        />
        {error && touched && (
          <span>
            <FontAwesomeIcon icon={faExclamationCircle} style={{ color: "red" }} />
          </span>
        )}
      </label>
    </div>
  );
};

export default TimeInput;
