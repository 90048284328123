import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { useFavorites } from "./FavoritesContext";
import "../../App.css";

interface HeartButtonProps {
  id: string;
}

const HeartButton: React.FC<HeartButtonProps> = ({ id }) => {
  const { ids, toggleId } = useFavorites();

  return (
    <>
      <button
        onClick={() => toggleId(id)}
        style={{
          position: "absolute",
          top: "3%",
          right: "3%",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          padding: "10px",
        }}
      >
        <FontAwesomeIcon
          icon={ids.includes(id) ? solidHeart : regularHeart}
          className={`heart-icon ${ids.includes(id) ? "pop-shake text-error" : ""}`}
        />
      </button>
    </>
  );
};

export default HeartButton;
