import React from "react";
import { useGetAllPollsQuery } from "../store/api/pollsApi";

export default function AgentsOverview(): JSX.Element {
  const { data: pollsApiResponse, isFetching, isError } = useGetAllPollsQuery();

  if (isFetching) {
    return <div>Laden...</div>;
  }

  if (isError) {
    return <div>Es gab ein Problem beim Abrufen der Daten.</div>;
  }

  return (
    <div className="grid grid-cols-5 gap-4">
      {pollsApiResponse?.locations.map(locationData =>
        locationData.data.map(poll => (
          <div key={poll.PollId}>
            <div className="max-w rounded-lg overflow-hidden p-30 md:p-6 bg-white hover:cursor-pointer shadow-lg hover:shadow-sm">
              <div className="mb-8">
                {/* Now using location from the locationData */}
                <div className="text-gray-900 font-bold text-xl mb-2">{locationData.location}</div>
                <ul>
                  {Object.entries(poll.Choices).map(([choice, votes]) => (
                    <li key={choice}>
                      {choice}: {votes} Stimmen
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )),
      )}
    </div>
  );
}
