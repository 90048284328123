import React from "react";
import cx from "clsx";
import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";

interface HeadButtonProps extends BaseComponentProps, WithChildrenProps {
  href?: string | undefined;
}

/**
 * Reusable component that renders a button
 * @param props
 */

export default function HeadButton(props: HeadButtonProps): JSX.Element {
  const { className, children, href } = props;

  const classes = cx(className, "tab btn hover:bg-primary no-border-color new-border-radius text-white mr-3");

  return (
    <a className={classes} href={href}>
      {children}
    </a>
  );
}
