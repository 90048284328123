import React from "react";
import cx from "clsx";
import { BaseComponentProps, WithChildrenProps } from "../../BaseComponentProps";

interface FloatingHeadlineProps extends BaseComponentProps, WithChildrenProps {
  id?: string | undefined;
}

/**
 * Reusable component that renders a floating headline
 * @param props
 */

export default function FloatingHeadline(props: FloatingHeadlineProps): JSX.Element {
  const { className, children, id } = props;

  const classes = cx(className, "flex justify-center items-center h-screen mt-8 font-bold leading-none");

  return (
    <h1 id={id} className={classes}>
      <div className="gradient-text text-5xl" style={{ lineHeight: "1.2" }}>
        {children}
      </div>
    </h1>
  );
}
