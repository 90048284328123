// src/api.ts
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";
import logger from "src/utils/logger";

const msalInstance = new PublicClientApplication(msalConfig);

async function getToken() {
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    throw new Error("No account found");
  } else {
    logger.log(JSON.stringify(account));
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      account: account,
      scopes: ["api://22eb1bfb-351d-4b96-8051-73a16fac8574/User.Read"],
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup({
        account: account,
        scopes: ["api://22eb1bfb-351d-4b96-8051-73a16fac8574/User.Read"],
      });
      logger.log(response.accessToken);
      return response.accessToken;
    } else {
      throw error;
    }
  }
}

export { getToken };
