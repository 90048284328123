import React, { useState, useMemo } from "react";
import Modal from "src/components/global/modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faArrowRight, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useCreateAttendancesMutation } from "src/store/api/timebookingApi";

import CalendarTileProjectName from "../calendar/CalendarTileProjectName";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { CreateAttendancesRequest, LogEntry } from "src/store/api/model/TimeBookingData";
import logger from "src/utils/logger";

import { toast } from "react-toastify";
import { t } from "i18next";

interface Props {
  logs?: LogEntry[];
  isOpen: boolean;
  onClose: () => void;
  initialDate: Date;
  userEmail: string;
  onCreated: () => void;
}

interface CreateAttendanceApiResponse {
  data?: {
    error?: boolean;
    statusCode?: number;
    headers?: any;
    text?: string;
  };
}

const CopyAttendanceModal: React.FC<Props> = ({ isOpen, onClose, initialDate, userEmail, logs, onCreated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectedDate = useMemo(() => new Date(initialDate), [initialDate]);

  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const [values, setValues] = useState<DateObject[]>([]);

  const [createExperiment] = useCreateAttendancesMutation();

  const formatiertesDatum = selectedDate.toLocaleDateString(t("timebooking.general.localeDateString"), {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const onError = (errorMessage: string, data: CreateAttendancesRequest) => {
    const entryDate = data.entries[0].date;
    const toastSelectedDateFormatted = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;

    toast.error(`Error on copy from ${toastSelectedDateFormatted} to ${entryDate} (${errorMessage})`, {
      autoClose: 20000,
    });
  };

  const onSuccess = (data: CreateAttendancesRequest) => {
    const entryDate = data.entries[0].date;
    const toastSelectedDateFormatted = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;

    toast.success(`Copied records from ${toastSelectedDateFormatted} to ${entryDate}`, {
      position: "top-right",
      autoClose: 12000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    onClose();
    onCreated();
    setValues([]);
  };

  const timestampToDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const isoDateString = date.toISOString();
    const formattedDate = isoDateString.substring(0, 10); // Nur die ersten 10 Zeichen für das Format "yyyy-mm-dd"
    return formattedDate;
  };

  const onSubmit = async (data: CreateAttendancesRequest) => {
    logger.log("Form data submitted:", data);
    setIsLoading(true);
    try {
      // passing the correct data type
      const response = await createExperiment({ entries: [...data.entries] }).unwrap(); // Spread to convert from iterable to array if needed
      logger.log("Server response:", response);
      setIsLoading(false);
      onSuccess(data); // Success toast notification
    } catch (error) {
      logger.error("Submission error:", error);
      setIsLoading(false);
      const typedError = error as CreateAttendanceApiResponse;
      const errorMessage = typedError.data?.text || "An unknown error occurred";
      onError(errorMessage, data); // Error toast notification
    }
  };

  const onCancel = () => {
    setValues([]);
    onClose();
  };

  const onSave = () => {
    values.forEach(date => {
      const dateString = date.toString();
      if (logs && Array.isArray(logs)) {
        const dataToSend = {
          entries: logs.map(log => {
            const isBreak = log.ProjectName === "Break Time [NEW]";
            return {
              type: isBreak ? "break" : "work",
              user: userEmail,
              date: timestampToDate(dateString),
              start_time: log.StartTime,
              end_time: log.EndTime,
              project_id: isBreak ? "" : log.ProjectId.toString(),
              task_id: isBreak ? "" : log.TaskId.toString(),
              comment: log.Comment,
            };
          }),
        };
        void onSubmit(dataToSend); // Call onSubmit for each dataToSend object
      }
    });
  };

  const handleDateChange = (selectedDates: DateObject | DateObject[] | null) => {
    if (selectedDates !== null) {
      const dates = Array.isArray(selectedDates) ? selectedDates : [selectedDates];
      setValues(dates);
    } else {
      // Handle null case
    }
  };

  return (
    <>
      <Modal open={isOpen} minWidth="45%" onClose={onClose}>
        <Modal.Header>
          <p className="text-3xl text-center mb-6 text-gray-500">{t("timebooking.general.copyTitle")} 🎉</p>
        </Modal.Header>
        <>
          <Modal.Body>
            <div className="flex justify-between items-center" style={{ display: "flex", minHeight: "120px" }}>
              <div
                className="relative card card-border-sm p-6 flex flex-col justify-center items-center"
                style={{ minWidth: "120px", minHeight: "120px", flex: 1 }}
              >
                <span className="text-center mb-4">{formatiertesDatum}</span>
                <>
                  {logs &&
                    logs.length > 0 &&
                    logs.map((log, index) => (
                      <CalendarTileProjectName
                        key={index}
                        taskName={log.TaskName}
                        projectName={log.ProjectName}
                        startTime={log.StartTime}
                        endTime={log.EndTime}
                        bookedHours={log.BookedHours}
                        bookedMinutes={log.BookedMinutes}
                      />
                    ))}
                </>
              </div>
              <div className="flex justify-center items-center" style={{ flex: 1 }}>
                <span className="text-3xl text-primary">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </div>
              <div className="flex justify-end items-center" style={{ flex: 1 }}>
                <Calendar
                  multiple
                  value={values}
                  onChange={handleDateChange}
                  // eslint-disable-next-line react/jsx-key
                  plugins={[<DatePanel />]}
                  weekStartDayIndex={1}
                  format="YYYY-MM-DD"
                  highlightToday={false}
                  numberOfMonths={1}
                  disableMonthPicker
                  disableYearPicker
                  minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                  maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)}
                  mapDays={({ date }) => {
                    let disabled;

                    const selectedDateDayOfWeek = selectedDate.toLocaleDateString(
                      t("timebooking.general.localeDateString"),
                      {
                        day: "numeric",
                      },
                    );

                    if (parseInt(selectedDateDayOfWeek) == date.day) {
                      disabled = true;
                    }

                    if (disabled) return { disabled: true };

                    const isWeekend = [0, 6].includes(date.weekDay.index);

                    if (isWeekend)
                      return {
                        disabled: true,
                        style: { color: "#ccc" },
                      };
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          {values.length > 0 && (
            <>
              <Modal.Footer>
                <div className="flex justify-between items-center w-full mt-6">
                  <div role="alert" className="alert alert-warning text-xl">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    Bitte beachte, dass die Kopierfunktion alle Datensätze überschreibt.
                    <button disabled={isLoading} className="btn" onClick={onCancel}>
                      Cancel
                    </button>
                    <button
                      form="attendances"
                      onClick={onSave}
                      className="btn btn-primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span>
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </span>
                      ) : (
                        "Copy Anyway"
                      )}
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default CopyAttendanceModal;
