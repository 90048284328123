import { t } from "i18next";
import React from "react";

interface WorkingTimeProps {
  isLoadingUserInfo: boolean;
  userInfo:
    | {
        weekly_working_hours?: string; // Changed to string
        work_schedule?: {
          name: string;
        };
      }
    | null
    | undefined;
}

const WorkingTime: React.FC<WorkingTimeProps> = ({ isLoadingUserInfo, userInfo }) => {
  return (
    <div className="stat place-items-center">
      <div className="stat-title">
        {t("timebooking.general.workingtime")} ({t("timebooking.general.times.hour", { count: 0 })})
      </div>
      {isLoadingUserInfo ? (
        <div className="animate-pulse bg-gray-200 rounded-md w-full h-6 my-2">&nbsp;</div>
      ) : (
        <>
          <div className="stat-value">{userInfo?.weekly_working_hours}</div>
          <div className="stat-desc">{userInfo?.work_schedule?.name}</div>
        </>
      )}
    </div>
  );
};

export default WorkingTime;
