import { t } from "i18next";
import React, { ChangeEventHandler, useEffect } from "react";

const setTheme = (theme: string) => {
  localStorage.setItem("theme", theme);
  document.documentElement.setAttribute("data-theme", theme);

  // Dynamically set the font-family variable
  if (theme === "thinkport") {
    document.documentElement.style.setProperty("--font-family-sans", "Libre Franklin");
  } else {
    document.documentElement.style.setProperty("--font-family-sans", "Syne");
  }

  // Apply the font-family to the body
  document.body.style.fontFamily = "var(--font-family-sans)";
};

const storedTheme = localStorage.getItem("theme") || "thinkport";
setTheme(storedTheme);

const handleThemeChange: ChangeEventHandler<HTMLInputElement> = e => {
  setTheme(e.target.value);
};

const ThemeSwitcher: React.FC = () => {
  useEffect(() => {
    // Set the initial theme font-family on mount
    const currentTheme = localStorage.getItem("theme") || "thinkport";
    setTheme(currentTheme);
  }, []);

  return (
    <div className="dropdown dropdown-top" style={{ width: "100%" }}>
      <div tabIndex={0} role="button" className="btn w-full btn-block btn-sm">
        {t("themes.title")}
      </div>
      <ul tabIndex={0} className="dropdown-content bg-base-300 rounded-box z-[1] w-52 p-2 shadow-2xl">
        <li>
          <input
            type="radio"
            name="theme-dropdown"
            className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
            aria-label={t("themes.thinkport")}
            value="thinkport"
            defaultChecked={storedTheme === "thinkport"}
            onChange={handleThemeChange}
          />
        </li>
        <li>
          <input
            type="radio"
            name="theme-dropdown"
            className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
            aria-label={t("themes.colorful")}
            value="light"
            defaultChecked={storedTheme === "light"}
            onChange={handleThemeChange}
          />
        </li>
      </ul>
    </div>
  );
};

export default ThemeSwitcher;
