import { RootState } from "../store";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

/**
 * Function to prepare the Authentication Headers
 * @param headers
 * @param api
 */
function prepareAuthHeaders(
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">,
) {
  const { getState } = api;
  // By default, if we have a token in the store, let's use that for authenticated requests
  const jwtToken = (getState() as RootState).user.jwtToken;
  if (jwtToken) {
    headers.set("Authorization", `Bearer ${jwtToken}`);
  }
}

function prepareApiHeaders(headers: Headers) {
  // TODO: implement config and REAL auth!!!
  headers.set("x-api-key", "kv8XAZi4HTzdBiJ7n9DnnLTyxVTvFL6EKBnDlCe0");
}

export default function prepareHeaders(
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">,
) {
  prepareAuthHeaders(headers, api);
  prepareApiHeaders(headers);

  return headers;
}
