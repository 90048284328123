import React, { useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { WithChildrenProps } from "../../BaseComponentProps";
import ContentContainer from "./ContentContainer";
import Header from "./Header";
import OnlineStatusComponent from "src/components/OnlineState";
import ToggleSidebarButton from "./ToggleSidebarButton";
import SpecialModalAd from "src/components/SpecialModalAd";

type BaseLayoutProps = WithChildrenProps;

export default function BaseLayout(props: BaseLayoutProps): JSX.Element {
  const { children } = props;
  const isAuthenticated = useIsAuthenticated();
  const [isAsideCollapsed, setIsAsideCollapsed] = useState(false);

  const toggleAside = () => {
    setIsAsideCollapsed(!isAsideCollapsed);
  };

  return (
    <div className="flex flex-row min-h-screen pt-4 items-start justify-center">
      {isAuthenticated && (
        <>
          <ToggleSidebarButton isCollapsed={isAsideCollapsed} toggleAside={toggleAside} />
          <aside
            className={`transition-all duration-300 ${isAsideCollapsed ? "w-0 overflow-hidden" : "xs:w-90 md:w-72"}`}
            style={{ flexShrink: 0 }}
          >
            {!isAsideCollapsed && <Header />}
          </aside>
        </>
      )}
      <main className={`flex-1 ${isAuthenticated && isAsideCollapsed ? "ml-4" : ""}`}>
        <SpecialModalAd
          delay={2000}
          reappearHours={1000}
          imageSrc="https://images-internal-thinkport-space-tiles.s3.eu-central-1.amazonaws.com/images/ads/BackOnlineAd.gif"
        />

        <OnlineStatusComponent />
        <ContentContainer>{children}</ContentContainer>
      </main>
    </div>
  );
}
