/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import { DefaultValues, FieldValues, useForm, Control, FieldErrors } from "react-hook-form";
import { BaseComponentProps, WithChildrenProps } from "../../../BaseComponentProps";
import cx from "clsx";
import Button from "../../button/Button";
import Checkbox from "../checkbox/SingleCheckbox";
import Logger from "../../../../utils/logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface FormProps<TFieldValues extends FieldValues> extends BaseComponentProps, WithChildrenProps {
  defaultValues?: DefaultValues<TFieldValues>;
  submitButtonText: React.ReactNode | string;
  onSubmit: (data: TFieldValues, event?: React.BaseSyntheticEvent) => Promise<void>;
  onSuccess: () => void;
  onError: (e: unknown) => void;
}

export default function Form<TFieldValues extends FieldValues>({
  children,
  onSuccess,
  onError,
  onSubmit,
  submitButtonText,
  className,
  defaultValues,
}: FormProps<TFieldValues>): JSX.Element {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TFieldValues>({
    mode: "onBlur",
    defaultValues,
  });

  const processFormFields = (
    childArray: React.ReactNode[],
    formControl: Control<TFieldValues>, // geändert von 'control' zu 'formControl'
    formErrors: FieldErrors<TFieldValues>, // Namenskonflikte vermeiden
  ): React.ReactNode => {
    return React.Children.map(childArray, child => {
      if (!React.isValidElement(child)) return child;

      const childProps: any = child.props;
      if (childProps.children) {
        return React.cloneElement(child, {
          ...childProps,
          children: processFormFields(React.Children.toArray(childProps.children), formControl, formErrors),
        });
      }

      const name = childProps.name;
      if (name && typeof child.type !== "string") {
        // Stellt sicher, dass es sich um eine Komponente und nicht um ein HTML-Element handelt
        return React.createElement(child.type, {
          ...childProps,
          control: formControl, // Verwende den geänderten Parameter 'formControl'
          error: formErrors[name],
          key: name,
        });
      }

      return child;
    });
  };

  const formClasses = cx(className);

  const onSubmitWrapper = async (data: TFieldValues) => {
    try {
      await onSubmit(data);
      onSuccess();
    } catch (e) {
      Logger.warn("onSubmit Error", e);
      reset();
      onError(e);
    }
  };

  return (
    <form className={formClasses} onSubmit={handleSubmit(onSubmitWrapper, onError)}>
      {processFormFields(React.Children.toArray(children), control, errors)}
      <div className="flex justify-end">
        {isSubmitting ? (
          <Button
            type="submit"
            className="tab btn no-border-color button-in-modal-bg-color hover:bg-primary new-shadow text-white"
            disabled
          >
            <FontAwesomeIcon icon={faSpinner} spin />
          </Button>
        ) : (
          <Button
            type="submit"
            className="tab btn no-border-color button-in-modal-bg-color hover:bg-primary new-shadow text-white"
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </form>
  );
}

Form.Checkbox = Checkbox;
