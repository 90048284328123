import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiResponse, Poll } from "./model/PollData"; // Adjust the import to use the simplified interfaces
// import prepareAuthHeadersApiKey from "./prepareAuthHeadersApiKey";

export const pollsApi = createApi({
  reducerPath: "pollsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://gttte5jzs2.execute-api.eu-central-1.amazonaws.com/live",
    prepareHeaders: headers => {
      const apiKey = "uSsF3g5MIo2dS5Hjyc8MF1gQ7ApJoab38aZzCZUH";
      headers.set("x-api-key", apiKey);
      return headers;
    },
  }),
  endpoints: builder => ({
    getAllPolls: builder.query<ApiResponse, void>({
      query: () => "/get-office-workload-based-on-polly-votes",
    }),
    // Assuming getPollById should return a single poll by location and id, its return type would be Poll.
    getPollById: builder.query<Poll, { location: string; pollId: string }>({
      query: ({ location, pollId }) => `/polls/${location}/${pollId}`,
    }),
  }),
});

export const { useGetAllPollsQuery, useGetPollByIdQuery } = pollsApi;
