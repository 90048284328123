import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

const OnlineStatusComponent: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const previousOnlineStatus = useRef<boolean>(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    intervalRef.current = setInterval(updateOnlineStatus, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  // Effect to show toast when online status changes
  useEffect(() => {
    if (isOnline !== previousOnlineStatus.current) {
      if (isOnline) {
        toast.success("You are back online!", {
          position: "bottom-right",
          autoClose: 5000,
        });
      } else {
        toast.error("You are offline. Check your internet connection.", {
          position: "bottom-right",
          autoClose: 15000,
        });
      }
      previousOnlineStatus.current = isOnline; // Update the previous status after showing the toast
    }
  }, [isOnline]);

  return <></>;
};

export default OnlineStatusComponent;
