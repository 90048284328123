import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";

//* TRANSLATIONS
import "./config/i18nConfig";

//* AUTHENTICATION
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";

//* LAYOUT
import BaseLayout from "./components/page/layout/BaseLayout";
import LandingPage from "./pages/LandingPage";
import AgentsPage from "./pages/AgentsPage";
import PollsPage from "./pages/PollsPage";
import MyAreaPage from "./pages/MyArea";
import GeneralInventoryPage from "./pages/GeneralInventoryPage";
import UsersPage from "./pages/UsersPage";
import BenefitManagementPage from "./pages/BenefitManagement";
import TimeTrackingPage from "./pages/TimeTracking";

declare global {
  interface Window {
    capta_app_config_agents: string;
    capta_app_config_datasources: string;
    capta_app_config_search: string;
  }
}

export default function App(): JSX.Element {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={<span>Loading</span>}>
            <BaseLayout>
              <UnauthenticatedTemplate>
                <LandingPage />
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <Routes>
                  <Route index element={<AgentsPage />} />
                  <Route path="/polls" element={<PollsPage />} />
                  <Route path="/my-inventory" element={<MyAreaPage />} />
                  <Route path="/general-inventory" element={<GeneralInventoryPage />} />
                  <Route path="/benefit-management" element={<BenefitManagementPage />} />
                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/deprecated-time-booking" element={<TimeTrackingPage />} />
                  <Route
                    path="/time-booking"
                    Component={() => {
                      window.location.href = "https://xit.thinkport.cloud/";
                      return null;
                    }}
                  />
                </Routes>
              </AuthenticatedTemplate>
            </BaseLayout>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
}
