import React from "react";

interface CalendarSkeletonProps {
  days: number; // Anzahl der Tage im Monat
}

const CalendarSkeleton: React.FC<CalendarSkeletonProps> = ({ days }) => {
  return (
    <>
      {/* Repeat this structure for the specified number of days */}
      {Array.from({ length: days }, (_, index) => (
        <div
          key={index}
          className="items-center justify-center group"
          style={{ minWidth: "120px", minHeight: "120px" }}
        >
          {/* Skeleton for the top-left date or label */}
          <div className="absolute mt-4 top-1 left-2 z-20">
            <div
              className="animate-pulse bg-gray-200 rounded-md w-10 h-6"
              style={{ animationDuration: `${Math.random() * 2 + 1}s` }}
            >
              &nbsp;
            </div>
          </div>

          {/* Skeleton for the main content area with logs */}
          <div
            className="bg-gray-200 rounded-md w-full h-24 mt-16 animate-pulse"
            style={{ animationDuration: `${Math.random() * 2 + 1}s` }}
          >
            &nbsp;
          </div>

          {/* Skeleton for the bottom content area, like the total booked time */}
          <div
            className="bg-gray-200 rounded-md w-full h-6 mt-2 animate-pulse"
            style={{ animationDuration: `${Math.random() * 2 + 1}s` }}
          >
            &nbsp;
          </div>

          {/* Optional: Skeleton for dropdown or ellipsis icon */}
          <div className="absolute mt-4 right-2 top-1 z-20" style={{ animationDuration: `${Math.random() * 2 + 1}s` }}>
            <div className="bg-gray-200 rounded-full w-6 h-6 animate-pulse">&nbsp;</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CalendarSkeleton;
