/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Modal from "src/components/global/modal/Modal";
import SelectDropdown from "src/components/global/form/dropdown/SelectDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confetti from "canvas-confetti";
import {
  faBriefcase,
  faCoffee,
  faSpinner,
  faTrash,
  faCommentAlt,
  faExclamationTriangle,
  faRefresh,
  faCheck,
  faBarsStaggered,
  faPlusCircle,
  faBoltLightning,
} from "@fortawesome/free-solid-svg-icons";

import {
  useCreateAttendancesMutation,
  useGetProjectsQuery,
  useGetProjectSuggestionQuery,
} from "src/store/api/timebookingApi";
import { getDay } from "date-fns";

import logger from "src/utils/logger";
import { CreateAttendancesRequest, LogEntry } from "src/store/api/model/TimeBookingData";
import { toast } from "react-toastify";
import TimeInput from "../TimeInput";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialDate: Date;
  userEmail: string;
  onCreated: () => void;
  logs?: LogEntry[];
}

interface CreateAttendanceApiResponse {
  data?: {
    error?: boolean;
    statusCode?: number;
    headers?: any;
    text?: string;
  };
}

const calculateDuration = (from: string, to: string) => {
  if (!from || !to || !from.includes(":") || !to.includes(":")) {
    return "00:00";
  }

  const fromParts = from.split(":").map(Number);
  const toParts = to.split(":").map(Number);

  // Erstellen von Date-Objekten mit Stunden und Minuten
  const fromDate = new Date(0, 0, 0, fromParts[0], fromParts[1]);
  const toDate = new Date(0, 0, 0, toParts[0], toParts[1]);

  // Differenz in Millisekunden zwischen toDate und fromDate
  let diff = toDate.getTime() - fromDate.getTime();

  // Falls 'to' früher als 'from' ist, wird ein Tag hinzugefügt
  if (diff < 0) {
    diff += 24 * 60 * 60 * 1000;
  }

  // Berechnung von Stunden und Minuten aus der Differenz
  const hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  const minutes = Math.floor(diff / 1000 / 60);

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

// Helper function to format date
const formatDate = (datum: Date): string => {
  const jahr = datum.getFullYear();
  const monat = (datum.getMonth() + 1).toString().padStart(2, "0");
  const tag = datum.getDate().toString().padStart(2, "0");
  return `${jahr}-${monat}-${tag}`;
};

const CreateAttendanceModal: React.FC<Props> = ({ isOpen, onClose, initialDate, userEmail, onCreated, logs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectedDate = useMemo(() => new Date(initialDate), [initialDate]);
  const [isUpdate, setIsUpdate] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const [createExperiment] = useCreateAttendancesMutation();
  const { data: projects, isLoading: isProjectsLoadingFromQuery, refetch } = useGetProjectsQuery(userEmail);
  const { data: projectSuggestion, isLoading: isProjectSuggestionLoadingFromQuery } = useGetProjectSuggestionQuery({
    weekday: "Friday",
  });
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [fetchCompleted, setFetchCompleted] = useState(false);

  const handleRefetch = async () => {
    try {
      setIsProjectsLoading(true);
      setFetchCompleted(false);
      await refetch();
      setFetchCompleted(true);
    } catch (error) {
      logger.error("Error refetching projects:", error);
    } finally {
      setIsProjectsLoading(false);
    }
  };

  const { control, handleSubmit, register, watch, setValue, reset } = useForm({
    defaultValues: {
      entries: logs?.map(log => ({
        type: log.ProjectName.includes("Break") ? "break" : "work",
        date: log.Date,
        start_time: log.StartTime,
        end_time: log.EndTime,
        project_id: log.ProjectId,
        task_id: log.TaskId,
        comment: log.Comment,
      })) || [
        {
          type: "work",
          date: formatDate(selectedDate),
          start_time: "",
          end_time: "",
          project_id: "",
          task_id: "",
          comment: "",
        },
      ],
    },
  });

  useEffect(() => {
    if (logs && logs.length > 0) {
      setIsUpdate(true);

      const sortedLogs = logs
        ? [...logs].sort((a, b) => {
            const startTimeA = new Date(`1970-01-01T${a.StartTime}`).getTime();
            const startTimeB = new Date(`1970-01-01T${b.StartTime}`).getTime();
            return startTimeA - startTimeB;
          })
        : [];

      reset({
        entries: sortedLogs.map(log => ({
          type: log.ProjectName.includes("Break") ? "break" : "work",
          date: log.Date,
          start_time: log.StartTime,
          end_time: log.EndTime,
          project_id: log.ProjectId,
          task_id: log.TaskId,
          comment: log.Comment,
        })),
      });
    }
  }, [reset, userEmail, logs]);

  const { fields, append, remove } = useFieldArray({ control, name: "entries" });
  const [showCommentFields, setShowCommentFields] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    fields.forEach((field, index) => {
      setValue(`entries.${index}.date`, formatDate(selectedDate));
    });
  }, [selectedDate, fields, setValue]);

  const splitEntries = () => {
    const entries = watch("entries");
    const newEntries: typeof entries = [];
    let currentWorkEntry: (typeof entries)[0] | null = null;

    entries.forEach(entry => {
      if (entry.type === "break" && currentWorkEntry) {
        if (currentWorkEntry.end_time > entry.start_time) {
          newEntries.push({
            ...currentWorkEntry,
            end_time: entry.start_time,
          });
          newEntries.push(entry);
          currentWorkEntry = {
            ...currentWorkEntry,
            start_time: entry.end_time,
          };
        } else {
          newEntries.push(currentWorkEntry);
          newEntries.push(entry);
          currentWorkEntry = null;
        }
      } else if (entry.type === "work") {
        if (currentWorkEntry) {
          newEntries.push(currentWorkEntry);
        }
        currentWorkEntry = entry;
      } else {
        newEntries.push(entry);
      }
    });

    if (currentWorkEntry) {
      newEntries.push(currentWorkEntry);
    }

    reset({ entries: newEntries });
  };

  const onError = (errorMessage: string) => {
    const message = isUpdate ? "Fehler beim Aktualisieren" : "Fehler beim Hinzufügen neuer Anwesenheiten";
    toast.error(`${message} (${errorMessage})`, {
      position: "top-right",
      autoClose: 20000,
    });
  };

  const onSuccess = () => {
    reset();
    const message = isUpdate ? "Erfolgreich aktualisiert" : "Neue Anwesenheiten hinzugefügt";
    toast.success(message, {
      position: "top-right",
      autoClose: 12000,
    });
    onClose();
  };

  useEffect(() => {
    // Wenn das Modal geschlossen wird, isUpdate zurücksetzen
    if (!isOpen) {
      setIsUpdate(false);
    }
  }, [isOpen]);

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: CreateAttendancesRequest) => {
    setIsLoading(true);
    try {
      const response = await createExperiment({ entries: [...data.entries] }).unwrap(); // Spread to convert from iterable to array if needed
      logger.log("Server response:", response);
      setIsLoading(false);
      onSuccess(); // Success toast notification
      onClose(); // Close modal after success
      onCreated();
    } catch (error) {
      logger.error("Submission error:", error);
      setIsLoading(false);
      const typedError = error as CreateAttendanceApiResponse;
      const errorMessage = typedError.data?.text || "An unknown error occurred";
      onError(errorMessage); // Error toast notification
    }
  };

  const breakProjectOption = [{ value: "PC: Non Working", label: "PC: Non Working" }];
  const breakTaskOption = [{ value: "Break Time", label: "Break Time" }];

  const formatiertesDatum = selectedDate.toLocaleDateString(t("timebooking.general.localeDateString"), {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const dayOfWeek = getDay(selectedDate);
  let message = "";
  let alertColor = "";
  let alertIcon = null;

  if (dayOfWeek === 6) {
    // Samstag: Gelbe Warnungsnachricht für Ausnahmefälle
    message = t("timebooking.general.alerts.saturdayWorking");
    alertColor = "alert-warning";
    alertIcon = <FontAwesomeIcon icon={faExclamationTriangle} className="stroke-current shrink-0 h-6 w-6 mr-2" />;
  } else if (dayOfWeek === 0) {
    // Sonntag: Rote Fehlermeldung mit Hinweis auf Absprache
    message = t("timebooking.general.alerts.sundayWorking");
    alertColor = "alert-error";
    alertIcon = <FontAwesomeIcon icon={faExclamationTriangle} className="stroke-current shrink-0 h-6 w-6 mr-2" />;
  }

  //for scrolling down in the modal to the bottom
  const lastEntryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastEntryRef.current) {
      lastEntryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [fields]);

  const calculateTotalDuration = (
    entries: { type: string; start_time: string; end_time: string }[],
    type: string,
  ): string => {
    let totalMinutes = 0;

    entries.forEach(entry => {
      if (entry.type === type && entry.start_time && entry.end_time) {
        const duration = calculateDuration(entry.start_time, entry.end_time);
        const [hours, minutes] = duration.split(":").map(Number);
        totalMinutes += hours * 60 + minutes;
      }
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${String(totalHours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`;
  };

  const [totalWorkTime, setTotalWorkTime] = useState("00:00");
  const [totalBreakTime, setTotalBreakTime] = useState("00:00");

  // Nutze useWatch anstelle von watch
  const entries = useWatch({ control, name: "entries" });

  useEffect(() => {
    setTotalWorkTime(calculateTotalDuration(entries, "work"));
    setTotalBreakTime(calculateTotalDuration(entries, "break"));
  }, [entries]);

  // Shortcuts für Hinzufügen
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey && event.altKey && event.code === "KeyB") {
        event.preventDefault(); // Prevent the default behavior
        append({
          type: "break",
          date: formatDate(selectedDate),
          start_time: "",
          end_time: "",
          project_id: "",
          task_id: "",
          comment: "",
        });
      } else if (event.shiftKey && event.altKey && event.code === "KeyW") {
        event.preventDefault(); // Prevent the default behavior
        append({
          type: "work",
          date: formatDate(selectedDate),
          start_time: "",
          end_time: "",
          project_id: "",
          task_id: "",
          comment: "",
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [append, selectedDate, isOpen]);

  return (
    <Modal open={isOpen} minWidth="75%" onClose={onClose}>
      <div ref={modalRef}>
        <Modal.Header>
          <p className="text-3xl text-center mb-1 text-gray-500">{formatiertesDatum}</p>
          <div className="text-xl text-center mb-3">
            <div className="badge badge-lg font-semibold bg-base-300 mb-1 text-md">
              <span className={parseInt(totalWorkTime.split(":")[0]) > 8 ? "text-error mr-1" : "text-success mr-1"}>
                {totalWorkTime}
              </span>
              {totalBreakTime !== "00:00" && (
                <div className="text-gray-500">
                  <span className="mr-1">+</span>
                  {totalBreakTime}
                  <FontAwesomeIcon icon={faCoffee} className="ml-1" style={{ fontSize: "67%" }} />
                </div>
              )}
            </div>
          </div>
        </Modal.Header>
        {dayOfWeek != 0 ? (
          <>
            <Modal.Body>
              <div style={{ maxHeight: "calc(80vh - 310px)", overflowY: "auto" }}>
                {message && (
                  <div role="alert" className={`alert ${alertColor}`}>
                    <div className="flex items-center text-lg font-semibold">
                      {alertIcon}
                      <span>{message}</span>
                    </div>
                  </div>
                )}
                <form id="attendances" onSubmit={handleSubmit(onSubmit)}>
                  <TransitionGroup component={null}>
                    {fields.map((field, index) => (
                      <CSSTransition key={field.id} timeout={300} classNames="fade">
                        <div
                          ref={index === fields.length - 1 ? lastEntryRef : null} // Reference for the last entry
                          key={fields[index].id}
                          className={`relative mt-4 ${
                            watch(`entries.${index}.type`) === "break" ? "bg-gray-200" : "timetrack-record"
                          } p-5 rounded-xl`}
                        >
                          <div
                            className="absolute top-0 right-0"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <div className="mr-1 mt-1 btn btn-xs btn-ghost text-sm text-gray-500 hover:text-error">
                              <span>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </div>
                          </div>

                          <div className="flex grid grid-cols-12">
                            <div className="flex items-center justify-begin">
                              <div className="flex mr-6 items-center text-xl text-gray-500">
                                <FontAwesomeIcon
                                  icon={watch(`entries.${index}.type`) === "break" ? faCoffee : faBriefcase}
                                />
                                <span className="ml-2">
                                  {watch(`entries.${index}.type`) === "break"
                                    ? t("timebooking.general.break")
                                    : t("timebooking.general.work")}
                                </span>
                              </div>
                            </div>
                            <div className="col-span-11 mr-6">
                              <input {...register(`entries.${index}.type`)} hidden />
                              {watch(`entries.${index}.type`) !== "break" && (
                                <>
                                  {isProjectsLoadingFromQuery ? (
                                    <div className="w-full animate-pulse">
                                      <div className="h-6 bg-gray-200 rounded-md w-full mb-6">&nbsp;</div>
                                    </div>
                                  ) : (
                                    <>
                                      <p className="text-sm mb-1">
                                        {projectSuggestion?.project_id !== "" &&
                                        !isProjectSuggestionLoadingFromQuery ? (
                                          <div>
                                            <span className="badge badge-secondary text-xs mr-1">
                                              {t("suggestion")}
                                            </span>
                                            <a
                                              className="link"
                                              href="#"
                                              onClick={e => {
                                                e.preventDefault();
                                                setValue(
                                                  `entries.${index}.project_id`,
                                                  projectSuggestion?.project_id || "",
                                                );
                                                setValue(`entries.${index}.task_id`, projectSuggestion?.task_id || "");
                                                void confetti({
                                                  particleCount: 150,
                                                  spread: 100,
                                                  origin: { y: 0.5 },
                                                  colors: ["FF5721", "01BBD3", "032648"],
                                                });

                                                // Hide the parent div
                                                (e.currentTarget as HTMLAnchorElement).parentElement!.style.display =
                                                  "none";

                                                // Focus on the input element
                                                const inputElement = document.querySelector<HTMLInputElement>(
                                                  `input[name="entries.${index}.start_time"]`,
                                                );
                                                inputElement?.focus();
                                              }}
                                            >
                                              {projectSuggestion?.project_name} &rarr; {projectSuggestion?.task_name}
                                            </a>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                      <Controller
                                        name={`entries.${index}.project_id`}
                                        control={control}
                                        render={({ field: projectField }) => {
                                          const entryType = watch(`entries.${index}.type`);
                                          return (
                                            <SelectDropdown
                                              required
                                              className="select select-bordered w-full mb-3"
                                              id={`entries.${index}.project_id`}
                                              placeholder={
                                                entryType === "work" ? t("timebooking.general.selectProject") : ""
                                              }
                                              disabled={entryType === "break"}
                                              options={
                                                entryType === "break"
                                                  ? breakProjectOption
                                                  : projects?.map(project => ({
                                                      value: project.project_id,
                                                      label: project.project_name,
                                                    })) ?? []
                                              }
                                              onChange={e => {
                                                projectField.onChange(e);
                                                setValue(`entries.${index}.task_id`, "");
                                              }}
                                              value={projectField.value}
                                            />
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                              <div className="flex grid grid-cols-2 gap-2">
                                <div>
                                  {watch(`entries.${index}.type`) !== "break" &&
                                    (isProjectsLoadingFromQuery ? (
                                      <div className="w-full animate-pulse">
                                        <div className="h-6 bg-gray-200 rounded-md w-full mb-6">&nbsp;</div>
                                      </div>
                                    ) : (
                                      <Controller
                                        name={`entries.${index}.task_id`}
                                        control={control}
                                        render={({ field: taskField }) => {
                                          const entryType = watch(`entries.${index}.type`);
                                          const selectedProjectId = watch(`entries.${index}.project_id`);
                                          const availableTasks =
                                            projects?.find(project => project.project_id === selectedProjectId)
                                              ?.tasks || [];
                                          return (
                                            <div className="flex flex-col items-start w-full">
                                              <div className="flex items-center w-full">
                                                <div className="flex-grow">
                                                  <SelectDropdown
                                                    required
                                                    className="select select-info w-full"
                                                    id={`entries.${index}.task_id`}
                                                    placeholder={
                                                      entryType === "work" ? t("timebooking.general.selectTask") : ""
                                                    }
                                                    disabled={entryType === "break"}
                                                    options={
                                                      entryType === "break"
                                                        ? breakTaskOption
                                                        : availableTasks.map(task => ({
                                                            value: task.task_id,
                                                            label: `${task.task_key}: ${task.task_name}`,
                                                          }))
                                                    }
                                                    onChange={e => {
                                                      taskField.onChange(e);
                                                    }}
                                                    value={taskField.value}
                                                  />
                                                </div>
                                                <div className="flex-shrink-0">
                                                  {entryType === "work" && (
                                                    <div className="flex justify-between items-center">
                                                      <div className="flex items-center">
                                                        {fetchCompleted && !isProjectsLoading && (
                                                          <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className="text-green-500 mr-1"
                                                          />
                                                        )}
                                                        <span
                                                          onClick={handleRefetch}
                                                          className={`btn btn-md btn-ghost ${
                                                            isProjectsLoading ? "btn-disabled" : ""
                                                          }`}
                                                        >
                                                          <FontAwesomeIcon icon={faRefresh} spin={isProjectsLoading} />
                                                        </span>
                                                      </div>
                                                      <span
                                                        className="btn btn-ghost"
                                                        onClick={e => {
                                                          e.preventDefault();
                                                          setShowCommentFields(currentShowCommentFields => ({
                                                            ...currentShowCommentFields,
                                                            [index]: !currentShowCommentFields[index],
                                                          }));
                                                        }}
                                                      >
                                                        <FontAwesomeIcon icon={faCommentAlt} />
                                                        {/* {t("timebooking.general.comment")} */}
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }}
                                      />
                                    ))}
                                </div>
                                <div className="glass rounded-lg">
                                  <div className="flex justify-start">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <TimeInput
                                        name={`entries.${index}.start_time`}
                                        register={register}
                                        minTime={index > 0 ? watch(`entries.${index - 1}.end_time`) : undefined}
                                        setValue={setValue}
                                        icon="play"
                                        value={watch(`entries.${index}.start_time`)}
                                      />
                                      <span className="ml-2 mr-2">-</span>
                                      <TimeInput
                                        name={`entries.${index}.end_time`}
                                        register={register}
                                        minTime={watch(`entries.${index}.start_time`)}
                                        setValue={setValue}
                                        icon="stop"
                                        value={watch(`entries.${index}.end_time`)}
                                      />
                                    </div>
                                    <div className="flex ml-6 items-center text-xl ">
                                      <span className="ml-1 mr-2 text-lg">
                                        {calculateDuration(
                                          watch(`entries.${index}.start_time`),
                                          watch(`entries.${index}.end_time`),
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <input {...register(`entries.${index}.comment`)} hidden />
                                  <input {...register(`entries.${index}.date`)} hidden />
                                </div>
                              </div>
                              {watch(`entries.${index}.task_id`) === "111055000004825873" && (
                                <span className="badge badge-warning mt-3">
                                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                  {t("timebooking.general.alerts.commentRequired")}
                                </span>
                              )}
                              {showCommentFields[index] && (
                                <div className="w-full mt-4">
                                  <input
                                    className="input w-full flex items-center"
                                    type="text"
                                    {...register(`entries.${index}.comment`)}
                                    placeholder={t("timebooking.general.commentPlaceholder")}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </form>
              </div>
              <div className="flex justify-center mt-2 items-center flex-col">
                <div className="flex">
                  <button
                    className="no-animation btn timetrack-record text-gray-500 p-4 rounded-xl"
                    type="button"
                    onClick={() =>
                      append({
                        type: "work",
                        date: formatDate(selectedDate),
                        start_time: "",
                        end_time: "",
                        project_id: "",
                        task_id: "",
                        comment: "",
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <FontAwesomeIcon className="ml-1" icon={faBriefcase} />
                    {t("timebooking.general.work")}
                  </button>
                  <button
                    className="btn text-gray-500 bg-base-300 p-4 ml-3 rounded-xl"
                    type="button"
                    onClick={() =>
                      append({
                        type: "break",
                        date: formatDate(selectedDate),
                        start_time: "",
                        end_time: "",
                        project_id: "",
                        task_id: "",
                        comment: "",
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <FontAwesomeIcon className="ml-1" icon={faCoffee} />
                    {t("timebooking.general.break")}
                  </button>
                </div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-4"></div>
                  <div role="alert" className="col-span-12 text-xs bg-gray-100 rounded-lg flex items-center p-4">
                    <FontAwesomeIcon className="text-primary mr-2" icon={faBoltLightning} />
                    <span className="font-bold mr-2">{t("timebooking.general.alerts.shortcutsMessage")}</span>
                    <span className="flex items-center break-all">
                      <kbd className="kbd kbd-xs mx-1 px-2 py-1">⌥</kbd>
                      <kbd className="kbd kbd-xs mx-1 px-2 py-1 mr-1">⇧</kbd>
                      <span className="mx-1">+</span>
                      <kbd className="kbd kbd-xs mx-1 px-2 py-1">B(reak)</kbd>
                      <span className="mx-1">/</span>
                      <kbd className="kbd kbd-xs mx-1 px-2 py-1">W(ork)</kbd>
                    </span>
                  </div>
                  <div className="col-span-4 hidden"></div> {/* Rechter Platzhalter */}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex justify-end">
                <div className="badge badge-lg font-semibold bg-base-300 mb-1 text-md">
                  <span className={parseInt(totalWorkTime.split(":")[0]) > 8 ? "text-error mr-1" : "text-success mr-1"}>
                    {totalWorkTime}
                  </span>
                  {totalBreakTime !== "00:00" && (
                    <div className="text-gray-500">
                      <span className="mr-1">+</span>
                      {totalBreakTime}
                      <FontAwesomeIcon icon={faCoffee} className="ml-1" style={{ fontSize: "67%" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div>
                  <button className="btn btn-warning ml-2" type="button" onClick={splitEntries} disabled={isLoading}>
                    {t("timebooking.general.splitEntries")} <FontAwesomeIcon icon={faBarsStaggered} />
                  </button>
                  <div className="dropdown dropdown-start dropdown-top">
                    <div tabIndex={0} role="button" className="ml-1 text-lg text-info">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </div>
                    <div
                      tabIndex={0}
                      className="card compact dropdown-content bg-base-100 rounded-box z-[1] w-64 shadow"
                    >
                      <div tabIndex={0} className="card-body">
                        <h2 className="card-title">Split Entries</h2>
                        <p>
                          Du kannst ab sofort noch schneller Zeiten tracken! <br />
                          <br />
                          Einfach Start und Endzeit als Block eintragen und die gebuchte Pause als Block. <br />
                          Anschließend auf &quot;Split Entries&quot; und Einträge werden gesplittet. <br /> <br />
                          <span className="text-warning">Bitte Prüfe die Einträge noch einmal.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="btn btn-default mr-2" onClick={onCancel} disabled={isLoading}>
                    {t("timebooking.general.cancel")}
                  </button>
                  <button form="attendances" className="btn btn-primary" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <span>
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </span>
                    ) : (
                      t("timebooking.general.save")
                    )}
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
            {message && (
              <div role="alert" className={`alert ${alertColor}`}>
                <div className="flex items-center text-lg font-semibold">
                  {alertIcon}
                  <span>{message}</span>
                </div>
              </div>
            )}
          </Modal.Body>
        )}
      </div>
    </Modal>
  );
};

export default CreateAttendanceModal;
