const clientId: string = process.env.REACT_APP_MS_CLIENT_ID ?? "whatever default";
const authority: string = process.env.REACT_APP_MS_AUTHORITY ?? "whatever default";

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: "https://internal.thinkport.space/",
    //redirectUri: "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["api://22eb1bfb-351d-4b96-8051-73a16fac8574/User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
